import React from "react";

const SkullIcon = ({ size = 124, className = "", ...props }) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 1125 2436"
		width={size}
		height={size}
		className={`fill-current ${className}`}
		preserveAspectRatio="xMidYMid meet" // Ensures scaling is proportional
		{...props}
  >
    <g> {/* chane path below to udpate logo */}
    <path class="st0" d="M776.52,1348.32c0,0,21.48-93.98,3.73-173.05c1.85,6.45,2.24,10.23,2.24,10.23s16.82-104.06-48.1-175.48
					c-21.29-29.68-41.35-57.49-40.74-87.04c0.52-2.06-1.26-4.11-4.1-4.79c-2.84-0.68-5.88,0.27-6.91,2.16
					c-7.98,9.33-17.94,24.04-23.12,42.3c-2.82,10.05-5.54,34.08-0.88,57.47c-32.81-29.31-44.56-94.23-43.13-88.74
					c-3.67-13.71-5.39-27.65-5.77-41.28c-0.49-13.43,0.32-38.63,1.63-50.94c0.48-1.9-1.14-3.72-3.85-4.43
					c-2.59-0.63-5.46,0.16-6.77,1.82c-21.3,22.14-45.49,50.63-68.62,66.39c-14.09,9.62-27.86,19-40.56,29.35
					c-17.52,14.31-32.89,30.38-44.55,51.45c-10.96,20.19-21.69,47.97-16.18,85.17c-0.09,0.06-0.19,0.12-0.28,0.18
					c-10.25-2.62-22.78-14.63-28.34-22.08c-7.26-9.62-10.8-23.42-10.67-41.13c0.01-2.08-1.93-3.87-4.72-4.24
					c-2.79-0.46-5.82,0.69-7.11,2.58c-16.84,24.06-28.92,48.04-35.76,71.79c-9.49,62.36-2.48,116.5-2.48,116.5s0.77-3.01,2.06-7.67
					c-15.59,76.92,4.85,163.49,4.85,163.49c8.06,45.26-17.19,51.22-16.08,77.78c1.11,26.56,12.77,40.41,37.12,53.69
					c24.35,13.28,37.16,18.93,47.12,14.5c9.96-4.43-7.75-43.46-7.75-43.46l33.61,30.67c0,0,11.57,67.98,11.57,93.44
					c0,25.46,35.82,26.34,35.82,26.34v-80.09l21.53,4.59v75.72h38.79v-72.08h25.54v72.08h37.89v-75.72l21.53-4.59v80.09
					c0,0,28.07-6.42,36.93-30.76c8.85-24.35,10.46-89.01,10.46-89.01l33.61-30.67l-7.75,43.46c0,0,20.56,5.42,47.12-14.5
					c26.56-19.92,31.59-27.12,37.12-53.69S768.46,1393.59,776.52,1348.32z M359.5,1139.5c0.05-0.06,0.11-0.12,0.16-0.18
					c-0.09,0.12-0.17,0.25-0.26,0.37C359.43,1139.63,359.47,1139.56,359.5,1139.5z M508.84,1403.54
					c-26.35,15.76-61.31,14.46-82.08,13.56c-10.18-0.41-19.73-5-26.4-12.7c-10.09-11.58-19.15-36.62-17.84-62.56
					c31.85-50.89,102.26-43.82,102.26-43.82c14.01,3.56,35.23,12.84,46.22,34.77C531,1332.78,531.72,1388.45,508.84,1403.54z
					 M590.83,1473.53c-4.19,2.79-13.92,4.5-17.48,4.55l-10.9-31.21l-10.86,31.21c-3.56-0.04-13.29-1.76-17.48-4.55
					c-4.19-2.84-6.13-8.02-5.09-12.97c7.25-34.36,25.09-62.11,25.09-62.11l8.33,0.09l8.38-0.09c0,0,17.84,27.74,25.09,62.11
					C596.95,1465.51,595.02,1470.69,590.83,1473.53z M724.58,1404.39c-6.67,7.7-16.22,12.3-26.4,12.7
					c-20.77,0.9-55.73,2.21-82.08-13.56c-22.89-15.09-22.16-70.76-22.16-70.76c10.99-21.93,32.21-31.21,46.22-34.77
					c0,0,70.41-7.07,102.26,43.82C743.73,1367.78,734.67,1392.82,724.58,1404.39z"/>
    </g>
  </svg>
);

export default SkullIcon;