import { BsFillLightningFill, BsGearFill } from 'react-icons/bs';
import { FaSkull } from 'react-icons/fa';
import { useContext } from 'react';
import { IoIosCreate } from 'react-icons/io';
import { NavLink } from 'react-router-dom';
import { AuthContext } from './AuthContext';
// import Logo from './Logo'
// import Logo from '../assets/Logo.svg';
// import { ReactComponent as SkullIcon  } from '../assets/Skull.svg';
import SkullIcon from "./SkullIcon";

const SideBar = () => {
  const { user, loading } = useContext(AuthContext); // Access user and loading state from AuthContext

  return (
    <div className="fixed sidebar top-0 left-0 h-screen w-16 flex flex-col bg-gray-900 text-white shadow-lg z-50">
      <NavLink to="/" className={({ isActive }) => (isActive ? 'active' : '')}>
      {/* to do convert this to logo */}
        <SideBarIcon icon={<SkullIcon  />} /> 
      </NavLink>
      <Divider />
      <NavLink to="/create" className={({ isActive }) => (isActive ? 'active' : '')}>
        <SideBarIcon icon={<IoIosCreate size="32" />} text={'Create 💡'} />
      </NavLink>
      
      {/* Only render the link to the user's page if the user is defined */}
      {user && (
        <NavLink to={`/userpage/${user.username}`} className={({ isActive }) => (isActive ? 'active' : '')}>
          <SideBarIcon icon={<BsFillLightningFill size="20" />} text={'Designs 💡'} />
        </NavLink>
      )}

      <Divider />
      <NavLink to="/settings" className={({ isActive }) => (isActive ? 'active' : '')}>
        <SideBarIcon icon={<BsGearFill size="22" />} />
      </NavLink>
    </div>
  );
};

const SideBarIcon = ({ icon, text }) => (
  <div className="sidebar-icon group">
    {icon}
    {text && <span className="sidebar-tooltip z-50 group-hover:scale-100">{text}</span>}
  </div>
);

const Divider = () => <hr className="sidebar-hr" />;

export default SideBar;
