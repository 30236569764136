import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);

    // Restore user session from localStorage on app load
    useEffect(() => {
        const loggedInUser = localStorage.getItem('user');
        if (loggedInUser) {
            setUser(JSON.parse(loggedInUser));
        }
        setLoading(false);
    }, []);

    

    // Standard username/password login
    const login = async (email, password) => {
        try {
            const response = await axios.post('https://koi-2028.onrender.com/login', { email, password });
            const user = response.data.user;
            setUser(user);
            localStorage.setItem('user', JSON.stringify(user)); // Persist user
            console.log('User:', user)
            return { success: true, user };
        } catch (error) {
            return {
                success: false,
                message: error.response?.data?.error || 'An error occurred during login.',
            };
        }
    };

    // Google login
    const googleLogin = (googleUserData) => {
        setUser(googleUserData); // Update context
        localStorage.setItem('user', JSON.stringify(googleUserData)); // Persist user
    };

    // Logout
    const logout = () => {
        setUser(null);
        localStorage.removeItem('user'); // Clear storage
    };

    // Update user profile globally
    const updateUserProfile = (updatedUserData) => {
        setUser(updatedUserData);
        localStorage.setItem('user', JSON.stringify(updatedUserData)); // Update storage
    };

    return (
        <AuthContext.Provider value={{ user, setUser, login, googleLogin, logout, updateUserProfile, loading }}>
            {children}
        </AuthContext.Provider>
    );
};