import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FaTimes } from 'react-icons/fa';  // Import an X icon from react-icons

const UpgradePrompt = ({ onClose }) => {
    const navigate = useNavigate();

    const handleUpgradeClick = () => {
        navigate('/subscribe');  // Redirect to the subscription/upgrade page
    };

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
            <div className="bg-white rounded-lg p-6 shadow-lg text-center relative max-w-md">
                
                {/* Close Button (X Icon) in the top-right corner */}
                <button 
                    className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
                    onClick={onClose}
                    aria-label="Close"
                >
                    <FaTimes size={20} />
                </button>

                <h2 className="text-3xl font-bold mb-4 text-gray-900">Unlock Unlimited Creativity</h2>
                <p className="text-lg mb-6 text-gray-700">
                    You’ve hit the limit of free designs for 5 days, but your creative journey doesn't have to stop here! Upgrade to <strong>Pro</strong> and enjoy limitless tattoo designs, exclusive features, and priority support.
                </p>
                <ul className="list-disc text-left ml-6 mb-6 text-gray-600">
                    <li className="mb-2">⚡ Unlimited designs</li>
                    <li className="mb-2">🌟 Access to premium models and tools</li>
                    <li className="mb-2">💬 Priority customer support</li>
                    <li className="mb-2">📈 Early access to new features</li>
                </ul>
                <button 
                    className="bg-rose-500 text-white px-6 py-3 rounded-full hover:bg-rose-400 mb-4 w-full font-semibold"
                    onClick={handleUpgradeClick}
                >
                    Upgrade to Pro
                </button>
                <p className="text-sm text-gray-500 mb-4">Starting at just $9.99/month</p>
            </div>
        </div>
    );
};

export default UpgradePrompt;
