// TopNavigation.js
import React, { useContext, useState, useEffect, useRef } from 'react';
import { AuthContext } from './AuthContext';
import { FaHashtag, FaBell, FaUserCircle } from 'react-icons/fa';
import Login from './Login';
import Signup from './Signup';

const TopNavigation = () => {
  const { user, logout } = useContext(AuthContext);
  const [showLogin, setShowLogin] = useState(false);
  const [showSignup, setShowSignup] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);

  const dropdownRef = useRef(null);

  const handleUserIconClick = () => {
    setShowDropdown(!showDropdown);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setShowDropdown(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className='top-navigation'>
      
      <span className='title-text'></span>

      {user ? (
        <div className='flex items-center space-x-2 mr-2 relative'>
          <FaBell className='top-navigation-icon text-[1.375rem]' />

          {/* Conditional rendering for avatar or default icon */}
          {user.user_avatar ? (
            <img
              src={user.user_avatar}
              alt="User Avatar"
              className='top-navigation-icon rounded-full cursor-pointer'
              style={{ width: '35px', height: '35px', objectFit: 'cover' }} // Set size and object fit for consistent styling
              onClick={handleUserIconClick}
            />
          ) : (
            <FaUserCircle
              className='top-navigation-icon text-[1.375rem]'
              onClick={handleUserIconClick}
            />
          )}

          {showDropdown && (
            <div
              ref={dropdownRef}
              className="absolute right-0 mt-1 top-full w-48 bg-white text-gray-800 border border-gray-300 rounded-lg shadow-lg z-50"
              style={{ zIndex: 1000 }}
            >
              <ul className="py-2">
              <li 
                className="px-4 py-2 cursor-pointer hover:bg-gray-100"
                onClick={() => window.location.href = `/#/userpage/${user.username}`}
              >
                {user.username}
              </li>
              <li
                  className="flex items-center px-4 py-2 cursor-pointer hover:bg-gray-100"
                  onClick={() => window.location.href = "/#/subscribe"}
                >
                  <span className="mr-2 text-yellow-500">💎</span>
                  Premium
                </li>
                
                <li className="px-4 py-2 cursor-pointer hover:bg-gray-100"
                onClick={() => window.location.href = "/#/settings"
                  
                }>  
                Settings and Privacy
                </li>
                <li className="px-4 py-2 cursor-pointer hover:bg-gray-100"
                onClick={() => window.location.href = "/#/support"}
                >Help
                </li>
                <li
                  className="px-4 py-2 cursor-pointer hover:bg-gray-100"
                  onClick={logout}
                >
                  Sign Out
                </li>
              </ul>
            </div>
          )}
        </div>
      ) : (
        <div>
          <button
            onClick={() => setShowLogin(true)}
            className='px-4 py-2 mr-2 text-sm font-semibold text-white bg-rose-500 rounded-lg hover:bg-rose-600'
          >
            Login
          </button>
          <button
            onClick={() => setShowSignup(true)}
            className='px-4 mr-2 py-2 text-sm font-semibold text-white bg-rose-500 rounded-lg hover:bg-rose-600'
          >
            Signup
          </button>
        </div>
      )}

      {/* Render the Login form as a modal if showLogin is true */}
      {showLogin && (
        <div className="modal">
            <div className="modal-content">
                <button className="close-button" onClick={() => setShowLogin(false)}>X</button>
                <Login onClose={() => setShowLogin(false)} />
            </div>
        </div>
    )}

      {/* Render the Signup form as a modal if showSignup is true */}
      {showSignup && (
        <div className="modal">
          <div className="modal-content">
            <button className="close-button" onClick={() => setShowSignup(false)}>X</button>
            <Signup onClose={() => setShowSignup(false)} />
          </div>
        </div>
      )}
    </div>
  );

  
  
};

export default TopNavigation;
