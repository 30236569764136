import React, { useState, useContext } from 'react';
import axios from 'axios';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import { useNavigate, useLocation } from 'react-router-dom';
import { AuthContext } from './AuthContext';

function Signup({ onClose }) {  // Accept onClose as a prop
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false);  // Loading state for signup process
    const { login, setUser } = useContext(AuthContext);  // Destructure login and setUser from AuthContext
    const navigate = useNavigate();
    const location = useLocation();

    // Get the previous page or set to home page if none
    const previousPage = location.state?.from || '/';

    // Handle standard form-based signup submission
    const handleSignup = async (e) => {
        e.preventDefault();
        setLoading(true);  // Start loading indicator
        setMessage('');  // Clear previous messages
        try {
            console.log('Sending signup request:', { username, email, password });
            
            // Send the signup request
            const response = await axios.post('https://koi-2028.onrender.com/register', {  
                username,
                email,
                password,
            });

            console.log('Signup successful, response:', response.data);
            
            // Attempt to log the user in automatically after signup
            const loginResult = await login(email, password);
            if (loginResult.success) {
                setMessage('Signup successful! Redirecting...');
                setTimeout(() => {
                    navigate(previousPage);  // Redirect to previous page after 2 seconds
                    onClose();  // Call onClose to close the signup modal
                }, 1000);  // Adjust the delay time if needed
            } else {
                setMessage(loginResult.message || 'Signup successful, but login failed.');
            }
        } catch (error) {
            console.error('Signup failed:', error);
            setMessage(error.response?.data?.error || 'An error occurred during signup.');
        } finally {
            setLoading(false);  // Stop loading indicator
        }
    };

    // Handle successful Google signup
    const handleGoogleSignupSuccess = async (response) => {
        setLoading(true);
        setMessage('');
        try {
            const result = await axios.post('https://koi-2028.onrender.com/auth/google/callback', {
                token: response.credential,
            });
            const { token, user } = result.data;
            setUser({ token, ...user });  // Set user in context to automatically log them in
            setMessage('Signup successful with Google! Redirecting...');
            setTimeout(() => {
                navigate(previousPage);  // Redirect to previous page after 2 seconds
                onClose();  // Call onClose to close the signup modal
            }, 1000);
        } catch (error) {
            console.error('Google signup failed:', error);
            setMessage('Google signup failed. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <GoogleOAuthProvider clientId="513619425094-tuoekhjks9mdb6cjoifrdc7m39g96vsa.apps.googleusercontent.com">
            <div className="flex items-center justify-centers">
                <div className="w-full max-w-md p-8 space-y-4 bg-white rounded-lg shadow-lg relative">
                    {/* Loading Indicator */}
                    {loading && (
                        <div className="absolute inset-0 bg-white bg-opacity-50 flex items-center justify-center">
                            <div className="loader">Loading...</div> {/* Replace with a spinner component */}
                        </div>
                    )}
                    <h2 className="text-2xl font-bold text-center text-gray-700">Signup</h2>
                    <form onSubmit={handleSignup} className="space-y-6">
                        <div>
                            <label className="block text-sm font-medium text-gray-700">Username</label>
                            <input
                                type="text"
                                value={username}
                                onChange={(e) => setUsername(e.target.value)}
                                required
                                className="w-full px-3 py-2 mt-1 text-gray-900 bg-gray-200 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500"
                            />
                        </div>
                        <div>
                            <label className="block text-sm font-medium text-gray-700">Email</label>
                            <input
                                type="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                                className="w-full px-3 py-2 mt-1 text-gray-900 bg-gray-200 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500"
                            />
                        </div>
                        <div>
                            <label className="block text-sm font-medium text-gray-700">Password</label>
                            <input
                                type="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                required
                                className="w-full px-3 py-2 mt-1 text-gray-900 bg-gray-200 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500"
                            />
                        </div>
                        {/* Submit Button */}
                        <div className="flex items-center justify-between">
                            <button
                                type="submit"
                                className="w-full px-4 py-2 font-medium text-white bg-rose-500 rounded-lg hover:bg-rose-600 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                disabled={loading}  // Disable button when loading
                            >
                                Signup
                            </button>
                        </div>
                    </form>
                    <div className="flex items-center justify-center mt-4 space-x-2">
                        <span className="text-sm font-medium text-gray-500">Or</span>
                    </div>
                    {/* Google Signup */}
                    <div className="flex items-center justify-center mt-4">
                        <GoogleLogin
                            onSuccess={handleGoogleSignupSuccess}
                            onError={() => setMessage('Google signup failed.')}
                            text="signup_with"
                            className="w-full"
                        />
                    </div>
                    {/* Message Display */}
                    {message && (
                        <div className="mt-4 text-center text-rose-500">
                            {message}
                        </div>
                    )}
                </div>
            </div>
        </GoogleOAuthProvider>
    );
}

export default Signup;
