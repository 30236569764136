import React, { useState, useEffect, useRef } from 'react';
import { FaEdit, FaShare, FaTrash, FaLink } from 'react-icons/fa';
import { useNavigate, useParams } from 'react-router-dom';
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  FacebookIcon,
  TwitterIcon,
  WhatsappIcon,
} from 'react-share';
import UserModal from './UserModal';
import axios from 'axios';

const UserTattoos = ({ userTattoos, onShare, onDelete, isProfileOwner, fetchTattooDetails }) => {
  const { tattooId, username } = useParams(); // Use the username and tattooId from URL params
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedTattoo, setSelectedTattoo] = useState(null);
  const [shareTattoo, setShareTattoo] = useState(null);
  const navigate = useNavigate();
  const shareRef = useRef();

  

  useEffect(() => {
    const handleClickOutside = (event) => {
      const shareMenu = document.querySelector(`[data-share-menu="share-menu-${shareTattoo?.id}"]`);
      if (shareMenu && !shareMenu.contains(event.target)) {
        setShareTattoo(null);
      }
    };
  
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [shareTattoo]);

  useEffect(() => {
  if (tattooId) {
    const tattooToPreview = userTattoos.find((tattoo) => tattoo.id.toString() === tattooId);
    if (tattooToPreview) {
      setSelectedTattoo(tattooToPreview);
      setIsModalOpen(true);
    } else {
      // If tattooId doesn't match any tattoo, navigate back to the user page
      navigate(`/userpage/${username}`);
    }
  } else {
    // Close the modal if the URL doesn't have a tattooId
    setIsModalOpen(false);
    setSelectedTattoo(null);
  }
}, [tattooId, userTattoos, navigate, username]);

const handleCardClick = (tattoo) => {
  if (isProfileOwner) {
    setSelectedTattoo(tattoo);
    setIsModalOpen(true);
    navigate(`/userpage/${username}/${tattoo.id}`);
  } else {
    navigate(`/userpage/${username}/${tattoo.id}`);
    fetchTattooDetails(tattoo.id);
  }
};

const handleCloseModal = () => {
  setIsModalOpen(false);
  setSelectedTattoo(null);
  if (isProfileOwner) {
    navigate(`/userpage/${username}`);
  }
};

  const handleEditClick = (tattoo) => {
    // Navigate to the create canvas page with the tattoo data for editing
    navigate('/create', { state: { tattoo } });
  };

  const handleShareClick = (tattoo) => {
    setShareTattoo((prev) => (prev?.id === tattoo.id ? null : tattoo));
  };

  const handleCopyLink = (url) => {
    navigator.clipboard.writeText(url).then(() => {
      alert('Link copied to clipboard!');
    });
  };

  return (
    <div className="content-list grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-2 p-4">
      {userTattoos.map((tattoo, index) => (
        <div
          key={index}
          className="bg-white shadow-md rounded-md hover:cursor-pointer hover:transform hover:-translate-y-1 hover:-translate-x-1 transition-transform duration-300 ease-in-out w-[20rem] sm:w-[18rem] md:w-[16rem] lg:w-[14rem] h-auto flex flex-col relative"
          style={{ aspectRatio: '6/8' }} 
          onClick={() => handleCardClick(tattoo)}
        >
          <div className="flex-grow border-2 rounded-t-md">
            <img
              src={tattoo.preview_image_url || 'https://via.placeholder.com/150'}
              alt={`Tattoo ${index}`}
              className="w-full h-full object-cover rounded-t-md"
            />
          </div>
          <div className="flex justify-center items-center p-2 bg-white rounded-b-md mb-1">
            <button
              className="flex items-center mr-4 content-container-icons text-black hover:text-rose-500"
              onClick={(e) => {
                e.stopPropagation();
                handleEditClick(tattoo);
              }}
            >
              <FaEdit className="mr-2" />
            </button>
            <div className="relative" ref={shareRef}>
              <button
                className="flex items-center mr-4 content-container-icons text-black hover:text-rose-500"
                onClick={(e) => {
                  e.stopPropagation();
                  handleShareClick(tattoo);
                }}
              >
                <FaShare className="mr-2" />
              </button>
              {shareTattoo === tattoo && (
                <div className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 bg-white shadow-md p-2 rounded-md flex gap-2" data-share-menu={`share-menu-${tattoo.id}`}>
                  <FacebookShareButton
                    url={`${window.location.origin}/#/userpage/${username}/${tattoo.id}`}
                    quote={`Check out this tattoo!`}
                    className="flex items-center"
                  >
                    <FacebookIcon size={32} round />
                  </FacebookShareButton>
                  <TwitterShareButton
                    url={`${window.location.origin}/#/userpage/${username}/${tattoo.id}`}
                    title={`Check out this tattoo!`}
                    className="flex items-center"
                  >
                    <TwitterIcon size={32} round />
                  </TwitterShareButton>
                  <WhatsappShareButton
                    url={`${window.location.origin}/#/userpage/${username}/${tattoo.id}`}
                    title={`Check out this tattoo!`}
                    className="flex items-center"
                  >
                    <WhatsappIcon size={32} round />
                  </WhatsappShareButton>
                  <button
                    onClick={() => handleCopyLink(`${window.location.origin}/#/userpage/${username}/${tattoo.id}`)}
                    className="flex items-center text-gray-500 hover:text-rose-500"
                  >
                    <FaLink size={24} className="mr-2" />
                  </button>
                </div>
              )}
            </div>
            <button
              className="flex items-center mr-4 content-container-icons text-black hover:text-rose-500"
              onClick={(e) => {
                e.stopPropagation();
                onDelete(tattoo.id);
              }}
            >
              <FaTrash className="mr-2" />
            </button>
          </div>
        </div>
      ))}
      {isProfileOwner && selectedTattoo && (
        <UserModal
          isOpen={isModalOpen}
          onClose={handleCloseModal}
          modelUrl={selectedTattoo.obj_file}
          images={selectedTattoo.images}
          positions={selectedTattoo.positions}
          rotations={selectedTattoo.rotations}
          scales={selectedTattoo.scales}
          tattoo={selectedTattoo}
          username={username}
          id={selectedTattoo.id}
          handleEditClick={handleEditClick}
          handleShareClick={handleShareClick}
          handleDeleteClick={onDelete}
        />
      )}
    </div>
  );
};

export default UserTattoos;
