import React, { useEffect, useRef, useState, useCallback, useContext } from 'react';
import * as BABYLON from '@babylonjs/core';
import '@babylonjs/loaders/glTF';
import '@babylonjs/loaders/OBJ/objFileLoader';
import { FaHeart, FaBookmark, FaShare, FaLink, FaUserCircle } from 'react-icons/fa';
import { FacebookShareButton, TwitterShareButton, WhatsappShareButton, FacebookIcon, TwitterIcon, WhatsappIcon } from 'react-share';
import { AuthContext } from './AuthContext'; 
import debounce from 'lodash.debounce';
import { useNavigate } from 'react-router-dom';

const ModelPreviewModal = ({ isOpen, onClose, modelUrl, images, positions, rotations, scales, tattoo, isUserPage = false, username, onStateChange }) => {
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();
  const currentUserId = user?.id;
  const canvasRef = useRef(null);
  const engineRef = useRef(null);
  const sceneRef = useRef(null);
  const dynamicTextureRef = useRef(null);
  const modelRef = useRef(null);
  const shareRef = useRef(null);
  const [isLoading, setIsLoading] = useState(true);
  const [shareVisible, setShareVisible] = useState(false);

  const [likeCount, setLikeCount] = useState(tattoo.likes_count || 0);
  const [favoriteCount, setFavoriteCount] = useState(tattoo.favorites_count || 0);
  const [isLiked, setIsLiked] = useState(tattoo.isLiked || false);
  const [isFavorited, setIsFavorited] = useState(tattoo.isFavorited || false);

  const uniqueTattooUrl = isUserPage
    ? `${window.location.origin}/#/userpage/${username}/${tattoo.id}`
    : `${window.location.origin}/#/tattoo/${tattoo.id}`;

  const { creator } = tattoo;

  const handleAvatarClick = () => {
    if (creator && creator.username) {
      navigate(`/userpage/${creator.username}`);
    }
  };

  const handleShareClick = useCallback((e) => {
    e.stopPropagation();
    setShareVisible((prev) => !prev);
  }, []);

  useEffect(() => {
    console.log("Props updated in modelPreviewModal:", tattoo.likes_count, tattoo.favorites_count, tattoo.isLiked, tattoo.isFavorited );
    setLikeCount(tattoo.likes_count);
    setFavoriteCount(tattoo.favorites_count);
    setIsLiked(tattoo.isLiked);
    setIsFavorited(tattoo.isFavorited);
  }, [tattoo.likes_count, tattoo.favorites_count, tattoo.isLiked, tattoo.isFavorited]);

  const handleCopyLink = useCallback(() => {
    navigator.clipboard.writeText(uniqueTattooUrl).then(() => {
      alert('Link copied to clipboard!');
    });
  }, [uniqueTattooUrl]);

  useEffect(() => {
    const fetchTattooState = async () => {
      if (currentUserId) {
        try {
          const queryString = new URLSearchParams({
            user_id: currentUserId,
            ids: tattoo.id,  
          }).toString();

          const response = await fetch(`https://koi-2028.onrender.com/tattoo_designs/state?${queryString}`, {
            method: 'GET',
          });

          if (response.ok) {
            const data = await response.json();
            if (data.length > 0) {
              setIsLiked(data[0].is_liked);  // Update to reflect the correct state
              setIsFavorited(data[0].is_favorited);  // Update to reflect the correct state
            }
          } else {
            console.error('Failed to fetch initial state:', response.statusText);
          }
        } catch (error) {
          console.error('Error fetching tattoo state:', error);
        }
      }
    };

    fetchTattooState();
  }, [tattoo.id, currentUserId]);

  // Update comment
  const handleLikeClick = useCallback(
    debounce(async () => {
      if (!currentUserId) return;
  
      const endpoint = isLiked ? `/tattoo_designs/${tattoo.id}/unlike` : `/tattoo_designs/${tattoo.id}/like`;
      const originalLikeCount = likeCount;
  
      // Optimistically update the state
      setIsLiked((prev) => !prev);
      setLikeCount(isLiked ? originalLikeCount - 1 : originalLikeCount + 1);
  
      try {
        const response = await fetch(`https://koi-2028.onrender.com${endpoint}`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ user_id: currentUserId }),
        });
  
        if (!response.ok) {
          console.error('Response:', await response.text());
          throw new Error(`HTTP error! status: ${response.status}`);
        }
  
        const data = await response.json();
        setLikeCount(data.likes || 0);
        
        // Notify parent component of the state change
        onStateChange({
          isLiked: !isLiked,
          likeCount: data.likes,
          isFavorited,
          favoriteCount,
        });
      } catch (error) {
        console.error('Error updating like status:', error);
        // Revert back to the original count on error
        setIsLiked((prev) => !prev);
        setLikeCount(originalLikeCount);
      }
    }, 100),
    [isLiked, likeCount, currentUserId, tattoo.id, onStateChange]
  );

  const handleFavoriteClick = useCallback(
    debounce(async () => {
      if (!currentUserId) return;

      const endpoint = isFavorited ? `/tattoo_designs/${tattoo.id}/unfavorite` : `/tattoo_designs/${tattoo.id}/favorite`;
      const originalFavoriteCount = favoriteCount;

      // Optimistically update the state
      setIsFavorited((prev) => !prev);
      setFavoriteCount(isFavorited ? originalFavoriteCount - 1 : originalFavoriteCount + 1);

      try {
        const response = await fetch(`https://koi-2028.onrender.com${endpoint}`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ user_id: currentUserId }),
        });

        if (!response.ok) {
          console.error('Response:', await response.text());
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        setFavoriteCount(data.total_favorites || 0);
        
        // Notify parent component of the state change
        onStateChange({ isFavorited: !isFavorited, favoriteCount: data.total_favorites });
      } catch (error) {
        console.error('Error updating favorite status:', error);
        // Revert back to the original count on error
        setIsFavorited((prev) => !prev);
        setFavoriteCount(originalFavoriteCount);
      }
    }, 100),
    [isFavorited, favoriteCount, currentUserId, tattoo.id, onStateChange]
  );

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (shareRef.current && !shareRef.current.contains(event.target)) {
        setShareVisible(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  

  // Initialize Babylon.js engine and scene
  useEffect(() => {
    if (!isOpen || !canvasRef.current) return;

    const initializeBabylon = async () => {
      if (!engineRef.current) {
        engineRef.current = new BABYLON.Engine(canvasRef.current, true);
      }

      if (!sceneRef.current) {
        sceneRef.current = new BABYLON.Scene(engineRef.current);
        const camera = new BABYLON.ArcRotateCamera("Camera", new BABYLON.Vector3(0, 1, -10), new BABYLON.Vector3(0, 1, 0), sceneRef.current);

        camera.panningSensibility = 5000; // Sensitivity of the panning
        
        camera.attachControl(canvasRef.current, true);
        new BABYLON.HemisphericLight("light", new BABYLON.Vector3(0, 1, 0), sceneRef.current);

        dynamicTextureRef.current = new BABYLON.DynamicTexture("dynamicTexture", { width: 2048, height: 2048 }, sceneRef.current);
      } else {
        sceneRef.current.clearColor = new BABYLON.Color4(0.1255, 0.1333, 0.1451, 1.0);
      }

      setIsLoading(true);  // Start loading model
      BABYLON.SceneLoader.ImportMesh("", "assets/", modelUrl, sceneRef.current, (meshes) => {
        modelRef.current = meshes[0];
        applyTattoosToModel(modelRef.current, images, positions, rotations, scales);
        setIsLoading(false);  // Model is loaded, stop loading state

        sceneRef.current.createDefaultCameraOrLight(true, true, true);
        sceneRef.current.activeCamera.alpha += Math.PI;
      });

      engineRef.current.runRenderLoop(() => {
        if (sceneRef.current) {
          sceneRef.current.render();
        }
      });

      const handleResize = () => {
        if (engineRef.current) {
          engineRef.current.resize();
        }
      };
      window.addEventListener('resize', handleResize);

      return () => {
        window.removeEventListener('resize', handleResize);
        if (engineRef.current) {
          engineRef.current.stopRenderLoop();
          engineRef.current.dispose();
          engineRef.current = null;
          sceneRef.current = null;
        }
      };
    };

    const applyTattoosToModel = (mesh, textures, positions, rotations, scales) => {
      if (!dynamicTextureRef.current) return;
    
      const context = dynamicTextureRef.current.getContext();
      context.clearRect(0, 0, 2048, 2048);
      context.fillStyle = "white";
      context.fillRect(0, 0, 2048, 2048);
    
      // Enable image smoothing for better quality
      context.imageSmoothingEnabled = true;
      context.imageSmoothingQuality = 'high';
    
      textures.forEach((textureURL, index) => {
        const image = new Image();
        image.crossOrigin = "anonymous";
        image.onload = () => {
          // Calculate the desired size for the image
          const maxSize = 400; // Maximum size for the base image
          const aspectRatio = image.width / image.height;
          let drawWidth = maxSize;
          let drawHeight = maxSize;
    
          if (aspectRatio > 1) {
            drawHeight = drawWidth / aspectRatio;
          } else {
            drawWidth = drawHeight * aspectRatio;
          }
    
          const { x, y } = positions[index];
          const rotation = rotations[index];
          const scale = scales[index];
    
          context.save();
          context.translate(x + drawWidth/2, y + drawHeight/2);
          context.rotate((rotation * Math.PI) / 180);
          context.scale(scale, scale);
          context.drawImage(image, -drawWidth/2, -drawHeight/2, drawWidth, drawHeight);
          context.restore();
    
          dynamicTextureRef.current.update();
        };
        image.onerror = (error) => {
          console.error("Failed to load image:", image.src, error);
        };
        image.src = textureURL;
      });
    
      if (!mesh.material) {
        const material = new BABYLON.StandardMaterial("tattooMaterial", sceneRef.current);
        material.diffuseTexture = dynamicTextureRef.current;
        material.backFaceCulling = false;
        mesh.material = material;
      } else {
        mesh.material.diffuseTexture = dynamicTextureRef.current;
      }
    };

    initializeBabylon();
  }, [isOpen, modelUrl, images, positions, rotations, scales]);

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      {isLoading && (
        <div className="absolute top-0 left-0 w-full h-full flex justify-center items-center z-10">
          <div className="spinner"></div>
        </div>
      )}
      <div className="bg-white rounded-lg overflow-hidden relative w-[80vw] h-[80vh] max-w-4xl flex">
        <button className="absolute top-3 right-3 close-button z-10 text-white" onClick={onClose}>
          X
        </button>
        <div className="flex-grow relative">
          <canvas ref={canvasRef} className="w-full h-full" />
        </div>
        <div className="absolute right-4 top-1/2 transform -translate-y-1/2 flex flex-col items-center space-y-4">
          <div className="rounded-full p-1 mb-2 hover:cursor-pointer" onClick={handleAvatarClick}>
            {creator && creator.user_avatar ? (
              <img src={creator.user_avatar} alt={creator.username || 'User'} className="w-12 h-12 rounded-full" />
            ) : (
              <FaUserCircle size={32} className="cursor-pointer text-white" />
            )}
          </div>
          <Interaction
            icon={<FaHeart className={`text-2xl cursor-pointer hover:text-rose-500  ${isLiked ? 'text-rose-500' : 'text-white'}`} />}
            displayNumber={likeCount}
            onClick={handleLikeClick}
          />
          <Interaction
            icon={<FaBookmark className={`text-2xl cursor-pointer hover:text-rose-500 ${isFavorited ? 'text-rose-500' : 'text-white'}`} />}
            displayNumber={favoriteCount}
            onClick={handleFavoriteClick}
          />
          <div className="relative" ref={shareRef}>
            <Interaction
              icon={<FaShare className="text-2xl text-white" />}
              onClick={handleShareClick}
            />
            {shareVisible && (
              <div className="absolute top-full right-0 mt-2 bg-white shadow-md p-2 rounded-md flex flex-row gap-2">
                <FacebookShareButton url={uniqueTattooUrl} quote={`Check out this tattoo!`}>
                  <FacebookIcon size={32} round />
                </FacebookShareButton>
                <TwitterShareButton url={uniqueTattooUrl} title={`Check out this tattoo!`}>
                  <TwitterIcon size={32} round />
                </TwitterShareButton>
                <WhatsappShareButton url={uniqueTattooUrl} title={`Check out this tattoo!`}>
                  <WhatsappIcon size={32} round />
                </WhatsappShareButton>
                <button onClick={handleCopyLink} className="flex items-center justify-center text-gray-500 hover:text-rose-500">
                  <FaLink size={24} />
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
      <style>
        {`
          .spinner {
            width: 50px;
            height: 50px;
            border: 6px solid rgba(255, 255, 255, 0.3);
            border-top-color: #fff;
            border-radius: 50%;
            animation: spin 1s linear infinite;
          }

          @keyframes spin {
            0% { transform: rotate(0deg); }
            100% { transform: rotate(360deg); }
          }
        `}
      </style>
    </div>
  );
};

// Reusable Interaction component
const Interaction = ({ icon, displayNumber, onClick }) => (
  <div
    onClick={onClick}
    className="group flex flex-col items-center hover:cursor-pointer"
  >
    <div className="rounded-full mb-1 hover:text-rose-500">
      {icon}
    </div>
    {displayNumber !== undefined && (
      <span className="text-white text-sm">
        {displayNumber}
      </span>
    )}
  </div>
);

export default ModelPreviewModal;