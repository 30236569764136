import React, { useState, useEffect, useContext, useRef, useCallback, memo } from 'react';
import { AuthContext } from './AuthContext';
import defaultTattoo from '../assets/tattooPlaceholder.png';
import { FaHeart, FaBookmark, FaShare, FaLink } from 'react-icons/fa';
import { FacebookShareButton, TwitterShareButton, WhatsappShareButton, FacebookIcon, TwitterIcon, WhatsappIcon } from 'react-share';
import debounce from 'lodash.debounce';

function DisplayTattoo({
  id,
  UserName,
  likes_count,
  favorites_count,
  preview_image_url,
  isLiked: initialIsLiked,
  isFavorited: initialIsFavorited,
  onClick,
  isVisible = false,
  resetToSkeleton = false,
  isUserPage = false,
  
}) {
  const { user } = useContext(AuthContext);
  const currentUserId = user?.id;
  const uniqueTattooUrl = isUserPage
  ? `${window.location.origin}/#/userpage/${UserName}/${id}`
  : `${window.location.origin}/#/tattoo/${id}`;

  // Initialize state with props values
  const [likeCount, setLikeCount] = useState(likes_count || 0);
  const [favoriteCount, setFavoriteCount] = useState(favorites_count || 0);
  const [isLiked, setIsLiked] = useState(initialIsLiked || false);
  const [isFavorited, setIsFavorited] = useState(initialIsFavorited || false);
  const [loading, setLoading] = useState(true);
  const [shareVisible, setShareVisible] = useState(false);

  const imgRef = useRef();
  const shareRef = useRef();

  // Fetch the tattoo state when the component mounts
  useEffect(() => {
    const fetchTattooState = async () => {
      if (currentUserId) {
        try {
          const queryString = new URLSearchParams({
            user_id: currentUserId,
            ids: id,  // Assuming `id` is a single tattoo ID
          }).toString();

          const response = await fetch(`https://koi-2028.onrender.com/tattoo_designs/state?${queryString}`, {
            method: 'GET',
          });

          if (response.ok) {
            const data = await response.json();
            if (data.length > 0) {
              setIsLiked(data[0].is_liked);  // Update to reflect the correct state
              setIsFavorited(data[0].is_favorited);  // Update to reflect the correct state
            }
          } else {
            console.error('Failed to fetch initial state:', response.statusText);
          }
        } catch (error) {
          console.error('Error fetching tattoo state:', error);
        }
      }
    };

    fetchTattooState();
  }, [id, currentUserId]);

  // Update counts and state when props change
  useEffect(() => {
    console.log("Props updated in DisplayTattoo:", { likes_count, favorites_count, initialIsLiked, initialIsFavorited });
    setLikeCount(likes_count);
    setFavoriteCount(favorites_count);
    setIsLiked(initialIsLiked);
    setIsFavorited(initialIsFavorited);
    
  }, [likes_count, favorites_count, initialIsLiked, initialIsFavorited]);

  // Reset to skeleton if required
  useEffect(() => {
    if (resetToSkeleton) {
      setLoading(true); // Set back to loading state when leaving view
    } else if (isVisible) {
      setLoading(true); // Show skeleton initially when becoming visible
    }
  }, [resetToSkeleton, isVisible]);

  // Set loading to false once the component is visible
  useEffect(() => {
    if (isVisible) {
      setLoading(false); // Set loading to false once visible again
    }
  }, [isVisible]);

  const handleFavoriteClick = useCallback(
    debounce(async () => {
      if (!currentUserId) return;

      const endpoint = isFavorited ? `/tattoo_designs/${id}/unfavorite` : `/tattoo_designs/${id}/favorite`;
      const originalFavoriteCount = favoriteCount;

      // Optimistically update the state
      setIsFavorited((prev) => !prev);
      setFavoriteCount(isFavorited ? originalFavoriteCount - 1 : originalFavoriteCount + 1);

      try {
        const response = await fetch(`https://koi-2028.onrender.com${endpoint}`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ user_id: currentUserId }),
        });

        if (!response.ok) {
          console.error('Response:', await response.text());
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        setFavoriteCount(data.total_favorites || 0); // Update to reflect the latest count from server
      } catch (error) {
        console.error('Error updating favorite status:', error);
        // Revert back to the original count on error
        setIsFavorited((prev) => !prev);
        setFavoriteCount(originalFavoriteCount);
      }
    }, 100),
    [isFavorited, favoriteCount, currentUserId, id]
  );

  const handleLikeClick = useCallback(
    debounce(async () => {
      if (!currentUserId) return;
  
      const endpoint = isLiked ? `/tattoo_designs/${id}/unlike` : `/tattoo_designs/${id}/like`;
      const originalLikeCount = likeCount;
  
      // Optimistically update the state
      setIsLiked((prev) => !prev);
      setLikeCount(isLiked ? originalLikeCount - 1 : originalLikeCount + 1);
  
      try {
        const response = await fetch(`https://koi-2028.onrender.com${endpoint}`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ user_id: currentUserId }),
        });
  
        if (!response.ok) {
          console.error('Response:', await response.text());
          throw new Error(`HTTP error! status: ${response.status}`);
        }
  
        const data = await response.json();
        setLikeCount(data.likes || 0); // Update to reflect the latest count from the server
  
        // Notify the parent `HomePage` component of the updated state
      
      } catch (error) {
        console.error('Error updating like status:', error);
        // Revert back to the original count on error
        setIsLiked((prev) => !prev);
        setLikeCount(originalLikeCount);
      }
    }, 100),
    [isLiked, likeCount, currentUserId, id, isFavorited, favoriteCount
    ]
  );

  const handleShareClick = useCallback((e) => {
    e.stopPropagation();
    setShareVisible((prev) => !prev);
  }, []);

  const handleSharePreview = (e) => {
    handleCopyLink(uniqueTattooUrl); // Ensure this only copies the link
  };
  
  const handleCopyLink = (url) => {
    navigator.clipboard.writeText(url).then(() => {
      alert('Link copied to clipboard!');
    }).catch(() => {
      alert('Failed to copy link');
    });
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (shareRef.current && !shareRef.current.contains(event.target)) {
        setShareVisible(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="content-list">
      <div
        className={`bg-white rounded-md shadow-md 
                    w-[21rem] sm:w-[18rem] md:w-[16rem] lg:w-[14rem] 
                    h-auto flex flex-col relative transition-transform duration-300 ease-in-out ${
                      isVisible ? 'hover:cursor-pointer hover:transform hover:-translate-y-1 hover:-translate-x-1' : ''
                    }`}
        style={{ aspectRatio: '6/8' }} // Maintain the aspect ratio
      >
        {/* Image Container */}
        <div
          className={`w-full h-full relative overflow-hidden rounded-md transition-opacity duration-500 ease-in-out ${
            loading ? 'opacity-0' : 'opacity-100'
          }`}
          style={{ aspectRatio: '6/8' }}
          onClick={isVisible ? onClick : undefined}
        >
          {/* Skeleton and Image Rendering */}
          {loading ? (
            <div className="skeleton w-full h-full rounded-t-md animate-pulse"></div>
          ) : (
            <img
              src={preview_image_url || defaultTattoo}
              alt={`${UserName}'s Tattoo`}
              className="w-full h-full object-cover rounded-t-md"
              ref={imgRef}
              onLoad={() => setLoading(false)}
              width={200}
              height={377}
            />
          )}
        </div>

        {/* Interaction features */}
        {/* <div className="flex justify-center items-center rounded-b-md p-2 bg-white relative">
          {loading ? (
            <div className="w-full h-8 bg-gray-300 animate-pulse rounded-b-md" />
          ) : (
            <>
              
              <Interaction icon={<FaHeart className={`${isLiked ? 'text-rose-500' : ''}`} />} displayNumber={likeCount} onClick={handleLikeClick} />
              
              
              <Interaction icon={<FaBookmark className={`${isFavorited ? 'text-rose-500' : ''}`} />} displayNumber={favoriteCount} onClick={handleFavoriteClick} />
              
              <div className="relative" ref={shareRef}>
                <Interaction icon={<FaShare />} onClick={handleShareClick} />
                {shareVisible && (
                  <div className="absolute top-full object-center transform -translate-x-1/2 mt-2 bg-white shadow-md p-2 rounded-md flex gap-2 z-auto">
                    <FacebookShareButton url={uniqueTattooUrl} quote={`Check out this tattoo!`} className="flex items-center">
                      <FacebookIcon size={32} round />
                    </FacebookShareButton>
                    <TwitterShareButton url={uniqueTattooUrl} title={`Check out this tattoo!`} className="flex items-center">
                      <TwitterIcon size={32} round />
                    </TwitterShareButton>
                    <WhatsappShareButton url={uniqueTattooUrl} title={`Check out this tattoo!`} className="flex items-center">
                      <WhatsappIcon size={32} round />
                    </WhatsappShareButton>
                    <button
                        onClick={(e) => {
                          e.stopPropagation();
                          handleSharePreview(); // Use the share preview function
                        }}
                        className="flex items-center text-gray-500 hover:text-rose-500"
                      >
                        <FaLink size={24} className="mr-2" />
                      </button>
                  </div>
                )}
              </div>
            </>
          )}
        </div> */}
      </div>
    </div>
  );
}

// Memoized Interaction component to prevent unnecessary re-renders
const Interaction = memo(({ icon, displayNumber, onClick }) => (
  <div onClick={onClick} className="flex items-center mr-4 content-container-icons hover:cursor-pointer">
    {icon}
    <div className="ml-2 min-h-[25px] flex items-center">{displayNumber > 0 ? displayNumber : ''}</div>
  </div>
));

export default memo(DisplayTattoo);