import React, { useState, useEffect, useRef, useCallback, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import * as BABYLON from '@babylonjs/core';
import '@babylonjs/loaders/glTF';
import '@babylonjs/loaders/OBJ/objFileLoader';
import TopNavigation from './TopNavigation';
import PreviewImages from './PreviewImages';
import { FaCloudUploadAlt, FaShare } from "react-icons/fa";
import { BsGearFill } from "react-icons/bs"; 
import { CiSaveDown1 } from "react-icons/ci";
import { MdOutlineFileDownload } from "react-icons/md";
import { AuthContext } from './AuthContext';
import axios from 'axios';
import UpgradePrompt from './UpgradePrompt';
import Signup from './Signup';

const CanvasIcon = ({ icon, text, onClick }) => (
    <div className="canvas-icons" onClick={onClick}>
        {icon}
        {text && <span className="canvas-tooltip">{text}</span>}
    </div>
);

const SettingsIcon = ({ icon, text, onClick }) => (
    <div className="settings-icon text-2xl cursor-pointer" onClick={onClick}>
        {icon}
        {text && <span className="canvas-tooltip">{text}</span>}
    </div>
);

function CreateCanvas() {
    const { state } = useLocation();
    const { tattoo } = state || {};

    const [tattooTextures, setTattooTextures] = useState(tattoo?.images || []);
    const [selectedImageIndex, setSelectedImageIndex] = useState(0);
    const [imagePositions, setImagePositions] = useState(tattoo?.positions || []);
    const [imageRotations, setImageRotations] = useState(tattoo?.rotations || []);
    const [imageScales, setImageScales] = useState(tattoo?.scales || []);
    const [savedImageUrl, setSavedImageUrl] = useState(null);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [selectedModel, setSelectedModel] = useState(tattoo?.obj_file || 'MaleTorso.obj');
    // Add tattoo
    const canvasRef = useRef(null);
    const engineRef = useRef(null);
    const sceneRef = useRef(null);
    const dynamicTextureRef = useRef(null);
    const humanoidRef = useRef(null);
    const dropdownRef = useRef(null);
    const { user } = useContext(AuthContext);
    const [isBabylonInitialized, setIsBabylonInitialized] = useState(false);
    const [showUpgradePrompt, setShowUpgradePrompt] = useState(false);
    const [showSignupModal, setShowSignupModal] = useState(false);
    const fileInputRef = useRef(null); 

    // update Build info

    const handleFileUpload = useCallback((event) => {
        if (!user) {
            setShowSignupModal(true); // Show signup modal if no user is logged in
            return;
        }

        const files = event.target.files;
        if (files && files.length > 0) {
            const newTextures = Array.from(files).map(file => URL.createObjectURL(file));
            setTattooTextures(prevTextures => [...prevTextures, ...newTextures]);
            setImagePositions(prevPositions => [...prevPositions, ...newTextures.map(() => ({ x: 800, y: 1000 }))]);
            setImageRotations(prevRotations => [...prevRotations, ...newTextures.map(() => 0)]);
            setImageScales(prevScales => [...prevScales, ...newTextures.map(() => 1)]);
        }
    }, [user]);

    const handleOpenFileInput = useCallback(() => {
        if (!user) {
            setShowSignupModal(true); // Open signup modal when user is not logged in
        } else {
            fileInputRef.current.click(); // Open file input if user is logged in
        }
    }, [user]);

    const handleDelete = useCallback((index) => {
        setTattooTextures(prevTextures => prevTextures.filter((_, i) => i !== index));
        setImagePositions(prevPositions => prevPositions.filter((_, i) => i !== index));
        setImageRotations(prevRotations => prevRotations.filter((_, i) => i !== index));
        setImageScales(prevScales => prevScales.filter((_, i) => i !== index));
    }, []);

    const handleSelect = useCallback((index) => {
        setSelectedImageIndex(index);
    }, []);

    const toggleDropdown = useCallback(() => {
        setIsDropdownOpen(prevState => !prevState);
    }, []);

    const handleModelChange = useCallback((model) => {
        setSelectedModel(model);
        setIsDropdownOpen(false);
    }, []);

    const initializeBabylon = useCallback(async () => {
        if (!canvasRef.current) return;

        if (engineRef.current) {
            engineRef.current.dispose();
        }

        engineRef.current = new BABYLON.Engine(canvasRef.current, true);
        sceneRef.current = new BABYLON.Scene(engineRef.current);
        const camera = new BABYLON.ArcRotateCamera("Camera", 2, Math.PI / 2, 100, BABYLON.Vector3.Zero(), sceneRef.current);
        camera.panningSensibility = 5000;
        
        camera.attachControl(canvasRef.current, true);
        new BABYLON.HemisphericLight("light", new BABYLON.Vector3(0, 1, 0), sceneRef.current);

        dynamicTextureRef.current = new BABYLON.DynamicTexture("dynamicTexture", { width: 2048, height: 2048 }, sceneRef.current);
        dynamicTextureRef.current.hasAlpha = true;

        sceneRef.current.clearColor = new BABYLON.Color4(0.1255, 0.1333, 0.1451, 1.0);

        try {
            humanoidRef.current = await createHumanoidModel(sceneRef.current);
        } catch (error) {
            console.error("Error loading humanoid model:", error);
        }

        engineRef.current.runRenderLoop(() => {
            if (sceneRef.current) {
                sceneRef.current.render();
            }
        });
        

        const handleResize = () => {
            if (engineRef.current) {
                engineRef.current.resize();
            }
        };
        window.addEventListener('resize', handleResize);

        setIsBabylonInitialized(true);

        return () => {
            window.removeEventListener('resize', handleResize);
            if (engineRef.current) {
                engineRef.current.stopRenderLoop();
                engineRef.current.dispose();
            }
        };
    }, [selectedModel]);

    useEffect(() => {
        initializeBabylon();
    }, [initializeBabylon]);

    useEffect(() => {
        if (isBabylonInitialized && humanoidRef.current) {
            applyTattoosToModel(humanoidRef.current, tattooTextures, imagePositions, imageRotations, imageScales);
        }
    }, [isBabylonInitialized, tattooTextures, imagePositions, imageRotations, imageScales]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsDropdownOpen(false);
            }
        };

        if (isDropdownOpen) {
            document.addEventListener("mousedown", handleClickOutside);
        } else {
            document.removeEventListener("mousedown", handleClickOutside);
        }

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [isDropdownOpen]);

    

    const applyTattoosToModel = useCallback((mesh, textures, positions, rotations, scales) => {
        if (!sceneRef.current || textures.length === 0) return;
    
        // Increase the dynamic texture resolution for better quality
        if (!dynamicTextureRef.current || dynamicTextureRef.current.getSize().width !== 2048) {
            dynamicTextureRef.current = new BABYLON.DynamicTexture("dynamicTexture", { width: 2048, height: 2048 }, sceneRef.current);
            dynamicTextureRef.current.hasAlpha = true;
        }
    
        const context = dynamicTextureRef.current.getContext();
        context.clearRect(0, 0, 2048, 2048);
        context.fillStyle = 'white';
        context.fillRect(0, 0, 2048, 2048);
    
        // Enable image smoothing for better quality
        context.imageSmoothingEnabled = true;
        context.imageSmoothingQuality = 'high';
    
        textures.forEach((textureURL, index) => {
            const image = new Image();
            image.crossOrigin = 'anonymous';
            console.log('Loading image from URL:', textureURL);
            image.src = textureURL;
            
            image.onload = () => {
                // Calculate the desired size for the image
                const maxSize = 400; // Maximum size for the base image
                const aspectRatio = image.width / image.height;
                let drawWidth = maxSize;
                let drawHeight = maxSize;
    
                if (aspectRatio > 1) {
                    drawHeight = drawWidth / aspectRatio;
                } else {
                    drawWidth = drawHeight * aspectRatio;
                }
    
                const { x, y } = positions[index];
                const rotation = rotations[index];
                const scale = scales[index];
    
                context.save();
                context.translate(x + drawWidth/2, y + drawHeight/2);
                context.rotate((rotation * Math.PI) / 180);
                context.scale(scale, scale);
    
                // Draw the image at the calculated size
                context.drawImage(image, -drawWidth/2, -drawHeight/2, drawWidth, drawHeight);
                context.restore();
        
                dynamicTextureRef.current.update();
        
                const material = new BABYLON.StandardMaterial("material", sceneRef.current);
                material.diffuseTexture = dynamicTextureRef.current;
                material.diffuseColor = new BABYLON.Color3(1, 1, 1);
                material.backFaceCulling = false;
                material.alpha = 1.0;
                mesh.material = material;
            };
        
            image.onerror = (err) => {
                console.error('Error loading image:', err);
            };
        });
    }, []);

    
    const createHumanoidModel = useCallback((scene) => {
        return new Promise((resolve, reject) => {
            BABYLON.SceneLoader.ImportMesh("", 'assets/', selectedModel, scene, (newMeshes) => {
                if (newMeshes.length > 0) {
                    resolve(newMeshes[0]);
                } else {
                    reject("No meshes were loaded");
                }
            });
        });
    }, [selectedModel]);

    // const createHumanoidModel = useCallback((scene) => {
    //     return new Promise((resolve, reject) => {
    //        
    //         const modelPath = `${process.env.PUBLIC_URL}/assets/`;
            
    //         BABYLON.SceneLoader.ImportMesh("", modelPath, selectedModel, scene, (newMeshes) => {
    //             if (newMeshes.length > 0) {
    //                 resolve(newMeshes[0]);
    //             } else {
    //                 reject("No meshes were loaded");
    //             }
    //         }, null, (scene, message) => {
    //             // Add error handling
    //             console.error('Model loading error:', message);
    //             reject(message);
    //         });
    //     });
    // }, [selectedModel]);

    const handleSliderChange = useCallback((index, axis, value) => {
        setImagePositions(prevPositions => {
            const updatedPositions = [...prevPositions];
            updatedPositions[index][axis] = value;
            return updatedPositions;
        });
    }, []);

    const handleRotationChange = useCallback((index, value) => {
        setImageRotations(prevRotations => {
            const updatedRotations = [...prevRotations];
            updatedRotations[index] = value;
            return updatedRotations;
        });
    }, []);

    const handleScaleChange = useCallback((index, value) => {
        setImageScales(prevScales => {
            const updatedScales = [...prevScales];
            updatedScales[index] = value;
            return updatedScales;
        });
    }, []);

    const saveCanvasAsImage = useCallback(() => {
        return new Promise((resolve) => {
            if (!canvasRef.current || !engineRef.current) return;

            engineRef.current.stopRenderLoop();
            sceneRef.current.render();

            canvasRef.current.toBlob(blob => {
                const imageUrl = URL.createObjectURL(blob);
                setSavedImageUrl(imageUrl);

                const link = document.createElement('a');
                link.href = imageUrl;
                link.download = 'tattoo-design.png';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);

                resolve(imageUrl);
            }, 'image/png');

            engineRef.current.runRenderLoop(() => {
                if (sceneRef.current) {
                    sceneRef.current.render();
                }
            });
        });
    }, []);

    const saveTattooDesign = async () => {
        if (!user) {
            alert("You need to be logged in to save a tattoo design.");
            return;
        }
    
        const targetWidth = 800;
        const targetHeight = 600; 
        const scaleFactor = 5; 
    
        // Capture the high-resolution image as a blob for preview
        const previewImageBlob = await captureHighResolutionImage(targetWidth, targetHeight, scaleFactor);
    
        // Create a FormData object to send files to the backend
        const formData = new FormData();
        formData.append('user_id', user.id);
        formData.append('positions', JSON.stringify(imagePositions));
        formData.append('rotations', JSON.stringify(imageRotations));
        formData.append('scales', JSON.stringify(imageScales));
        formData.append('obj_file', selectedModel);  // Add other necessary fields
    
        // Add the preview image blob to the form data
        formData.append('preview_image', previewImageBlob, 'preview_image.png');
    
        // Append each tattoo texture as a file (these can be blob URLs or actual image files)
        for (let i = 0; i < tattooTextures.length; i++) {
            const blobUrl = tattooTextures[i];
            try {
                const response = await fetch(blobUrl);
                const tattooBlob = await response.blob();
                formData.append('images', tattooBlob, `image_${i}.jpg`);  // Multiple images
            } catch (error) {
                console.error(`Error fetching tattoo image ${i}:`, error);
            }
        }
    
        try {
            const response = await axios.post('https://koi-2028.onrender.com/tattoo_designs', formData, {
                headers: { 'Content-Type': 'multipart/form-data' }
            });
    
            if (response.status === 201) {
                alert('Tattoo design saved successfully!');
            }
        } catch (error) {
            // Handle the 403 error when the user has used all their free designs
            if (error.response && error.response.status === 403) {
                setShowUpgradePrompt(true);  // Show upgrade prompt if user is out of free designs
            } else {
                console.error('Error saving tattoo design:', error);
                alert('An error occurred while saving the tattoo design. Please try again.');
            }
        }
    };

    const handleUpgrade = () => {
        // Handle upgrade logic here, e.g., redirect to payment page
        alert("Redirecting to the upgrade page...");
    };

    const handleClosePrompt = () => {
        setShowUpgradePrompt(false);
    };

    const captureHighResolutionImage = useCallback((targetWidth, targetHeight, scaleFactor = 5) => { 
        return new Promise((resolve) => {
            if (!canvasRef.current || !engineRef.current) return;
    
            engineRef.current.stopRenderLoop();
            sceneRef.current.render();
    
            const newWidth = targetWidth * scaleFactor;
            const newHeight = targetHeight * scaleFactor;
    
            const offscreenCanvas = document.createElement('canvas');
            offscreenCanvas.width = newWidth;
            offscreenCanvas.height = newHeight;
            const offscreenContext = offscreenCanvas.getContext('2d');
    
            offscreenContext.drawImage(
                canvasRef.current,
                0, 0, canvasRef.current.width, canvasRef.current.height,
                0, 0, newWidth, newHeight
            );
    
            offscreenCanvas.toBlob((blob) => {
                resolve(blob);  // Return the blob directly
            }, 'image/png', 1.0);
    
            engineRef.current.runRenderLoop(() => {
                if (sceneRef.current) {
                    sceneRef.current.render();
                }
            });
        });
    }, []);
    
    return (
        <div className="flex canvas-content-container">
            <div className="flex flex-col flex-1">
                
                <div className="grid grid-cols-1 xl:grid-cols-3 md:grid-cols-3 sm:grid-cols-3 gap-4 mt-16 sm:mt-0 p-4 sm:pl-20 h-full">
                    <div className="md:block md:col-span-1 sm:block sm:col-span-1 h-full flex flex-col">
                        <div className='border rounded-lg border-black text-center mb-4 bg-slate-100'>
                            <label 
                                className='w-5 h-3 text-center border-r-1 cursor-pointer'
                                onClick={(e) => {
                                if (!user) {
                                    e.preventDefault(); // Prevent default action of opening file explorer
                                    setShowSignupModal(true); // Show signup modal if not signed in
                                } else {
                                    fileInputRef.current.click(); // Trigger file input click when user is signed in
                                }
                                }}
                            >
                                <div className='w-full h-full rounded-md items-center p-5'>
                                <FaCloudUploadAlt size={70} className='upload-icon item w-full mt-2' />
                                <p className='mt-2'>Drag and drop or Click here <br/> to upload an image</p> 
                                <span className="block text-sm mt-2 text-slate-600">Upload any image from desktop</span>
                                </div>
                            </label>
                            <input 
                                type="file" 
                                accept='image/*' 
                                ref={fileInputRef} 
                                onChange={handleFileUpload} 
                                multiple 
                                hidden 
                            />
                            </div>
                            <div className="canvas-side-elements mb-4 bg-slate-100">
                                <h2 className="underline mb-2">Control Panel</h2>
                                <div>
                                    <label>X Position:</label>
                                    <input
                                        type="range"
                                        min="-1000"
                                        max="2000"
                                        value={imagePositions[selectedImageIndex]?.x || 0}
                                        onChange={(e) => {
                                            if (tattooTextures.length === 0) {
                                                if (!user) {
                                                    setShowSignupModal(true); // Trigger login/signup modal
                                                } else {
                                                    alert("Please upload an image to adjust controls.");
                                                }
                                            } else {
                                                handleSliderChange(selectedImageIndex, 'x', parseInt(e.target.value));
                                            }
                                        }}
                                        className="slider"
                                    />
                                </div>
                                <div>
                                    <label>Y Position:</label>
                                    <input
                                        type="range"
                                        min="-1000"
                                        max="2000"
                                        value={imagePositions[selectedImageIndex]?.y || 0}
                                        onChange={(e) => {
                                            if (tattooTextures.length === 0) {
                                                if (!user) {
                                                    setShowSignupModal(true); // Trigger login/signup modal
                                                } else {
                                                    alert("Please upload an image to adjust controls.");
                                                }
                                            } else {
                                                handleSliderChange(selectedImageIndex, 'y', parseInt(e.target.value));
                                            }
                                        }}
                                        className="slider"
                                    />
                                </div>
                                <div>
                                    <label>Rotate:</label>
                                    <input
                                        type="range"
                                        min="0"
                                        max="360"
                                        value={imageRotations[selectedImageIndex] || 0}
                                        onChange={(e) => {
                                            if (tattooTextures.length === 0) {
                                                if (!user) {
                                                    setShowSignupModal(true); // Trigger login/signup modal
                                                } else {
                                                    alert("Please upload an image to adjust controls.");
                                                }
                                            } else {
                                                handleRotationChange(selectedImageIndex, parseInt(e.target.value));
                                            }
                                        }}
                                        className="slider"
                                    />
                                </div>
                                <div>
                                    <label>Scale:</label>
                                    <input
                                        type="range"
                                        min="0.1"
                                        max="5"
                                        step="0.1"
                                        value={imageScales[selectedImageIndex] || 0}
                                        onChange={(e) => {
                                            if (tattooTextures.length === 0) {
                                                if (!user) {
                                                    setShowSignupModal(true); // Trigger login/signup modal
                                                } else {
                                                    alert("Please upload an image to adjust controls.");
                                                }
                                            } else {
                                                handleScaleChange(selectedImageIndex, parseFloat(e.target.value));
                                            }
                                        }}
                                        className="slider"
                                    />
                                </div>
                            </div>
                        <div className='layers bg-slate-100 mb-5'>
                            <h1 className='underline mb-2'>Layers</h1>
                            <div className=''>
                                <PreviewImages images={tattooTextures} onDelete={handleDelete} onSelect={handleSelect} selectedImageIndex={selectedImageIndex} />
                            </div>
                        </div>
                    </div>
                    <div className="md:col-span-2 flex flex-col mb-5 relative">
                        <canvas ref={canvasRef} id="renderCanvas" className='rounded-md' style={{ width: '100%', height: '100%', backgroundColor: 'transparent' }} onClick={() => setIsDropdownOpen(false)}></canvas> {/* Close dropdown on canvas click */}
                        <div className="absolute top-2 right-2">
                            <SettingsIcon icon={<BsGearFill />} onClick={toggleDropdown} />
                            {isDropdownOpen && (
                                <div ref={dropdownRef} className="absolute right-0 mt-1 top-full w-48 bg-white text-gray-800 border border-gray-300 rounded-lg shadow-lg">
                                    <ul className="py-2">
                                        <li className="px-4 py-2 cursor-pointer hover:bg-gray-100" onClick={() => handleModelChange('MaleTorso.obj')}>Male Torso</li>
                                        <li className="px-4 py-2 cursor-pointer hover:bg-gray-100" onClick={() => handleModelChange('FemaleTorso.obj')}>Female Torso</li>
                                        <li className="px-4 py-2 cursor-pointer hover:bg-gray-100" onClick={() => handleModelChange('Arm_Right_Vertical.obj')}>Arm</li>
                                        <li className="px-4 py-2 cursor-pointer hover:bg-gray-100" onClick={() => handleModelChange('Right_Hand_Vertical4.obj')}>Hand</li>
                                        <li className="px-4 py-2 cursor-pointer hover:bg-gray-100" onClick={() => handleModelChange('MaleLeg_Right.obj')}>Leg</li>
                                        {/* <li className="px-4 py-2 cursor-pointer hover:bg-gray-100" onClick={() => handleModelChange('MFullBody2.obj')}>Another Full Body</li> */}
                                    </ul>
                                </div>
                            )}
                        </div>
                        <div className='canvas-bottom-element flex mt-2'>
                            <div className="flex">
                                <CanvasIcon icon={<CiSaveDown1 />} text="Save" onClick={saveTattooDesign} />
                                <CanvasIcon icon={<MdOutlineFileDownload />} text="Download" onClick={saveCanvasAsImage} />
                            </div>
                            <div className='flex items-center justify-end mr-4'>
                                <CanvasIcon icon={<FaShare />} text="Share" />
                            </div>
                        </div>
                    </div>
                </div>
                {showUpgradePrompt && <UpgradePrompt onClose={handleClosePrompt} onUpgrade={handleUpgrade} />} {/* Show the UpgradePrompt when needed */}
                {showSignupModal && (
                    <div className="modal">
                        <div className="modal-content">
                        <button className="close-button" onClick={() => setShowSignupModal(false)}>X</button>
                        <Signup onClose={() => setShowSignupModal(false)} />
                        </div>
                    </div>
                    )}
            </div>
        </div>
    );
}

export default CreateCanvas;
