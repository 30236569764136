import React, { useEffect, useState, useContext } from 'react';
import { Navigate } from 'react-router-dom';
import axios from 'axios';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  ArcElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';
import { Bar, Pie } from 'react-chartjs-2';
import { AuthContext } from './AuthContext';

// Register ChartJS components
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  ArcElement,
  Title,
  Tooltip,
  Legend
);

const Dashboard = () => {
  const [metrics, setMetrics] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [filter, setFilter] = useState('last30days');
  const [showModal, setShowModal] = useState(false);
  const { user } = useContext(AuthContext);

  useEffect(() => {
    if (user && user.is_admin) {
      axios
        .get('https://koi-2028.onrender.com/dashboard-metrics')
        .then((response) => {
          setMetrics(response.data);
          setLoading(false);
        })
        .catch((err) => {
          console.error('Error fetching dashboard data:', err);
          setError('Failed to load dashboard data.');
          setLoading(false);
        });
    }
  }, [user]);

  const calculatePercentageChange = (current, previous) => {
    if (!previous) return 'N/A';
    return (((current - previous) / previous) * 100).toFixed(2) + '%';
  };

  const retryFetch = () => {
    setLoading(true);
    setError(null);
    axios
      .get('https://koi-2028.onrender.com/dashboard-metrics')
      .then((response) => {
        setMetrics(response.data);
        setLoading(false);
      })
      .catch((err) => {
        console.error('Error fetching dashboard data:', err);
        setError('Failed to load dashboard data.');
        setLoading(false);
      });
  };

  const exportToCSV = () => {
    const csvData = JSON.stringify(metrics);
    const blob = new Blob([csvData], { type: 'text/csv' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = 'dashboard_metrics.csv';
    link.click();
  };

  if (!user || !user.is_admin) {
    return <Navigate to="/" />;
  }

  if (loading)
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="text-lg font-semibold">Loading dashboard...</div>
      </div>
    );

  if (error)
    return (
      <div className="flex justify-center items-center h-screen text-red-600">
        {error}
        <button onClick={retryFetch} className="btn ml-4">
          Retry
        </button>
      </div>
    );

  if (!metrics) return null;

  const sortedDesignsPerDay = metrics.design_metrics.designs_per_day.sort(
    (a, b) => new Date(a.date) - new Date(b.date)
  );

  const designsBarData = {
    labels: sortedDesignsPerDay.map((d) => d.date),
    datasets: [
      {
        label: 'Designs Per Day',
        data: sortedDesignsPerDay.map((d) => d.count),
        backgroundColor: '#FFCE56',
      },
    ],
  };

  const barOptions = {
    responsive: true,
    plugins: {
      tooltip: {
        callbacks: {
          label: function (context) {
            const percentChange = calculatePercentageChange(
              context.raw,
              sortedDesignsPerDay[context.dataIndex - 1]?.count
            );
            return `${context.raw} designs (${percentChange})`;
          },
        },
      },
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Designs Created Per Day (with % change)',
      },
    },
  };

  const userPieData = {
    labels: ['Free Users', 'Premium Users'],
    datasets: [
      {
        data: [
          metrics.user_metrics.free_users,
          metrics.user_metrics.premium_users,
        ],
        backgroundColor: ['#36A2EB', '#FF6384'],
      },
    ],
  };

  return (
    <div className="ml-16 mx-auto p-6">
      <h1 className="text-3xl font-bold mb-6 text-center">Application Dashboard</h1>

      {/* Filters */}
      <div className="flex space-x-4 mb-6 ">
        <button onClick={() => setFilter('last7days')} className="dash-btn">
          Last 7 Days
        </button>
        <button onClick={() => setFilter('last30days')} className="dash-btn">
          Last 30 Days
        </button>
        <button onClick={() => setFilter('thisYear')} className="dash-btn">
          This Year
        </button>
        <button onClick={exportToCSV} className="dash-btn">
          Export Metrics
        </button>
      </div>

      {/* User Metrics */}
      <section className="mb-10">
        <h2 className="text-2xl font-semibold mb-4">User Metrics</h2>
        <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
          <div className="bg-blue-100 p-4 rounded-lg shadow">
            <p className="text-gray-600">Total Users</p>
            <p className="text-lg font-bold">
              {metrics.user_metrics.total_users}
            </p>
          </div>
          <div className="bg-pink-100 p-4 rounded-lg shadow">
            <p className="text-gray-600">Premium Users</p>
            <p className="text-lg font-bold">
              {metrics.user_metrics.premium_users}
            </p>
          </div>
          <div className="bg-green-100 p-4 rounded-lg shadow">
            <p className="text-gray-600">Free Users</p>
            <p className="text-lg font-bold">
              {metrics.user_metrics.free_users}
            </p>
          </div>
        </div>
        <div className="mt-6">
          <Pie
            data={userPieData}
            options={{
              responsive: true,
              plugins: {
                legend: {
                  position: 'top',
                },
                title: {
                  display: true,
                  text: 'User Distribution',
                },
              },
            }}
          />
        </div>
      </section>

      {/* Design Metrics */}
      <section className="mb-10">
        <h2 className="text-2xl font-semibold mb-4">Design Metrics</h2>
        <div className="mt-6">
          <Bar data={designsBarData} options={barOptions} />
        </div>
      </section>
    </div>
  );
};

export default Dashboard;
