import React, { useEffect, useRef, useState, useCallback } from 'react';
import * as BABYLON from '@babylonjs/core';
import '@babylonjs/loaders/glTF';
import '@babylonjs/loaders/OBJ/objFileLoader';
import { FaEdit, FaShare, FaLink, FaTrash } from 'react-icons/fa';
import { FacebookShareButton, TwitterShareButton, WhatsappShareButton, FacebookIcon, TwitterIcon, WhatsappIcon } from 'react-share';

const UserModal = ({ 
    isOpen, 
    onClose, 
    modelUrl, 
    images, 
    positions, 
    rotations, 
    scales, 
    tattoo, 
    handleEditClick, 
    handleShareClick, 
    handleDeleteClick,
    
    username,
    id
}) => {
    const canvasRef = useRef(null);
    const engineRef = useRef(null);
    const sceneRef = useRef(null);
    const dynamicTextureRef = useRef(null);
    const modelRef = useRef(null);
    const shareRef = useRef(null);
    const [isLoading, setIsLoading] = useState(true);
    const [shareVisible, setShareVisible] = useState(false);

    const uniqueTattooUrl = `${window.location.origin}/#/userpage/${username}/${id}`;
    

    const handleShareClickInternal = useCallback((e) => {
        e.stopPropagation();
        setShareVisible((prev) => !prev);
    }, []);

    const handleCopyLink = useCallback(() => {
        navigator.clipboard.writeText(uniqueTattooUrl).then(() => {
            alert('Link copied to clipboard!');
        });
    }, [uniqueTattooUrl]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (shareRef.current && !shareRef.current.contains(event.target)) {
                setShareVisible(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        if (!isOpen || !canvasRef.current) return;

        const initializeBabylon = async () => {
            if (!engineRef.current) {
                engineRef.current = new BABYLON.Engine(canvasRef.current, true);
            }

            if (!sceneRef.current) {
                sceneRef.current = new BABYLON.Scene(engineRef.current);
                const camera = new BABYLON.ArcRotateCamera("Camera", new BABYLON.Vector3(0, 1, -10), new BABYLON.Vector3(0, 1, 0), sceneRef.current);
                camera.attachControl(canvasRef.current, true);
                new BABYLON.HemisphericLight("light", new BABYLON.Vector3(0, 1, 0), sceneRef.current);

                dynamicTextureRef.current = new BABYLON.DynamicTexture("dynamicTexture", { width: 2048, height: 2048 }, sceneRef.current);
            } else {
                sceneRef.current.clearColor = new BABYLON.Color4(0.1255, 0.1333, 0.1451, 1.0);
            }

            setIsLoading(true);
            BABYLON.SceneLoader.ImportMesh("", "assets/", modelUrl, sceneRef.current, (meshes) => {
                modelRef.current = meshes[0];
                applyTattoosToModel(modelRef.current, images, positions, rotations, scales);
                setIsLoading(false);

                sceneRef.current.createDefaultCameraOrLight(true, true, true);
                sceneRef.current.activeCamera.alpha += Math.PI;
            });

            engineRef.current.runRenderLoop(() => {
                if (sceneRef.current) {
                    sceneRef.current.render();
                }
            });

            const handleResize = () => {
                if (engineRef.current) {
                    engineRef.current.resize();
                }
            };
            window.addEventListener('resize', handleResize);

            return () => {
                window.removeEventListener('resize', handleResize);
                if (engineRef.current) {
                    engineRef.current.stopRenderLoop();
                    engineRef.current.dispose();
                    engineRef.current = null;
                    sceneRef.current = null;
                }
            };
        };

        const applyTattoosToModel = (mesh, textures, positions, rotations, scales) => {
            if (!dynamicTextureRef.current) return;
          
            const context = dynamicTextureRef.current.getContext();
            context.clearRect(0, 0, 2048, 2048);
            context.fillStyle = "white";
            context.fillRect(0, 0, 2048, 2048);
          
            // Enable image smoothing for better quality
            context.imageSmoothingEnabled = true;
            context.imageSmoothingQuality = 'high';
          
            textures.forEach((textureURL, index) => {
              const image = new Image();
              image.crossOrigin = "anonymous";
              image.onload = () => {
                // Calculate the desired size for the image
                const maxSize = 400; // Maximum size for the base image
                const aspectRatio = image.width / image.height;
                let drawWidth = maxSize;
                let drawHeight = maxSize;
          
                if (aspectRatio > 1) {
                  drawHeight = drawWidth / aspectRatio;
                } else {
                  drawWidth = drawHeight * aspectRatio;
                }
          
                const { x, y } = positions[index];
                const rotation = rotations[index];
                const scale = scales[index];
          
                context.save();
                context.translate(x + drawWidth/2, y + drawHeight/2);
                context.rotate((rotation * Math.PI) / 180);
                context.scale(scale, scale);
                context.drawImage(image, -drawWidth/2, -drawHeight/2, drawWidth, drawHeight);
                context.restore();
          
                dynamicTextureRef.current.update();
              };
              image.onerror = (error) => {
                console.error("Failed to load image:", image.src, error);
              };
              image.src = textureURL;
            });
          
            if (!mesh.material) {
              const material = new BABYLON.StandardMaterial("tattooMaterial", sceneRef.current);
              material.diffuseTexture = dynamicTextureRef.current;
              material.backFaceCulling = false;
              mesh.material = material;
            } else {
              mesh.material.diffuseTexture = dynamicTextureRef.current;
            }
          };

        initializeBabylon();
    }, [isOpen, modelUrl, images, positions, rotations, scales]);

    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
            {isLoading && (
                <div className="absolute top-0 left-0 w-full h-full flex justify-center items-center z-10">
                    <div className="spinner"></div>
                </div>
            )}
            <div className="bg-white rounded-lg overflow-hidden relative w-[80vw] h-[80vh] max-w-4xl flex">
                <button className="absolute top-3 right-3 close-button z-10 text-white" onClick={onClose}>
                    X
                </button>
                <div className="flex-grow relative">
                    <canvas ref={canvasRef} className="w-full h-full" />
                </div>
                <div className="absolute right-4 top-1/2 transform -translate-y-1/2 flex flex-col items-center space-y-4">
                    <button
                        className="flex items-center mr-4 text-white text-2xl hover:text-rose-500"
                        onClick={(e) => {
                            e.stopPropagation();
                            handleEditClick(tattoo);
                        }}
                    >
                        <FaEdit className="mr-2" />
                    </button>
                    <div className="relative" ref={shareRef}>
                        <button
                            className="flex items-center mr-4 text-white text-2xl hover:text-rose-500"
                            onClick={handleShareClickInternal}
                        >
                            <FaShare className="mr-2" />
                        </button>
                        {shareVisible && (
                            <div className="absolute bottom-full  transform right-1/4 mb-2 bg-white shadow-md p-2 rounded-md flex gap-2 z-50">
                                <FacebookShareButton url={uniqueTattooUrl} quote={`Check out this tattoo!`} className="flex items-center">
                                    <FacebookIcon size={32} round />
                                </FacebookShareButton>
                                <TwitterShareButton url={uniqueTattooUrl} title={`Check out this tattoo!`} className="flex items-center">
                                    <TwitterIcon size={32} round />
                                </TwitterShareButton>
                                <WhatsappShareButton url={uniqueTattooUrl} title={`Check out this tattoo!`} className="flex items-center">
                                    <WhatsappIcon size={32} round />
                                </WhatsappShareButton>
                                <button
                                    onClick={() => handleCopyLink()}
                                    className="flex items-center text-gray-500 hover:text-rose-500"
                                >
                                    <FaLink size={24} className="mr-2" />
                                </button>
                            </div>
                        )}
                    </div>
                    <button
                        className="flex items-center mr-4 text-white text-2xl hover:text-rose-500"
                        onClick={(e) => {
                            e.stopPropagation();
                            handleDeleteClick(tattoo.id);
                        }}
                    >
                        <FaTrash className="mr-2" />
                    </button>
                </div>
            </div>
        </div>
    );
};

export default UserModal;
