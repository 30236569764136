// src/utils/analytics.js
import ReactGA from 'react-ga4';

const initializeAnalytics = () => {
  ReactGA.initialize('G-H3YQE71L9S'); // Replace with your GA4 measurement ID
};

const logPageView = (path) => {
  ReactGA.send({ hitType: "pageview", page: path });
};

export { initializeAnalytics, logPageView };