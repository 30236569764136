import React, { useState, useEffect, useContext, useRef, useCallback, lazy, Suspense } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import debounce from 'lodash.debounce';
import { throttle } from 'lodash';
import { AuthContext } from './AuthContext';
import ModelPreviewModal from './ModelPreviewModal';

// to do: new routes

const DisplayTattoo = lazy(() => import('./DisplayTattoo')); // Lazy load DisplayTattoo component

const tattoosCache = {};  // Existing cache for tattoos previews
const tattooDetailsCache = {}; // New cache for storing full tattoo details

function HomePage() {
  const { tattooId } = useParams();
  const navigate = useNavigate();
  const { user } = useContext(AuthContext); // Get user from AuthContext

  const [state, setState] = useState({
    tattoos: [], // Store all tattoo previews
    visibleTattoos: [],
    resetSkeletons: {},
    loadingStates: {},
    selectedTattoo: null,
    isModalOpen: false,
    isLoading: true,
    isFetching: false,
    scrollLock: false,
  });

  const initialModalOpened = useRef(false);
  const tattooRefs = useRef({});
  const observer = useRef(null);

  // Fetch tattoo previews (including likes and favorites data)
  const fetchTattooPreviews = useCallback(async (batchSize = 20, append = false) => {
    // Check if the cache already has the necessary preview data
    if (tattoosCache[batchSize] && append) {
      setState((prevState) => ({
        ...prevState,
        tattoos: [...prevState.tattoos, ...tattoosCache[batchSize]],
        isLoading: false,
        isFetching: false,
      }));
      return;
    } else if (tattoosCache[batchSize] && !append) {
      setState((prevState) => ({
        ...prevState,
        tattoos: tattoosCache[batchSize],
        isLoading: false,
        isFetching: false,
      }));
      return;
    }

    setState((prevState) => ({ ...prevState, isLoading: true, isFetching: true }));

    const start = append ? state.tattoos.length : 0;
    const end = start + batchSize;

    try {
      // Pass user_id as a query parameter to get is_liked and is_favorited information if the user is logged in
      const userIdQuery = user ? `&user_id=${user.id}` : '';
      const response = await fetch(`https://koi-2028.onrender.com/tattoo_designs/previews?start=${start}&end=${end}${userIdQuery}`);
      if (response.ok) {
        const data = await response.json();
        tattoosCache[batchSize] = append ? [...state.tattoos, ...data] : data;

        setState((prevState) => ({
          ...prevState,
          tattoos: append ? [...prevState.tattoos, ...data] : data,
          isLoading: false,
          isFetching: false,
        }));
      } else {
        console.error('Error fetching tattoo previews:', response.statusText);
      }
    } catch (error) {
      console.error('Error fetching tattoo previews:', error);
    }
  }, [state.tattoos, user]);


  // Fetch full tattoo details on click
  const fetchTattooDetails = useCallback(async (tattooId) => {

    const userId = user?.id;  // Get the user ID

    const url = `https://koi-2028.onrender.com/tattoo_designs/${tattooId}?user_id=${userId}`;

    try {
        const response = await fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        });

        if (response.ok) {
            const data = await response.json();
            setState((prevState) => ({
                ...prevState,
                selectedTattoo: data,  // This now includes both tattoo details and user-specific info
                isModalOpen: true,  // Open the modal
            }));
        } else {
            console.error('Error fetching tattoo details:', response.statusText);
        }
    } catch (error) {
        console.error('Error fetching tattoo details:', error);
    }
}, [user]);

  // Fetch initial tattoo previews on component mount
  useEffect(() => {
    fetchTattooPreviews();
  }, [fetchTattooPreviews]);


  // Open modal if tattooId is present in URL
  useEffect(() => {
    if (!state.isLoading && tattooId && state.tattoos.length > 0 && !initialModalOpened.current) {
      fetchTattooDetails(tattooId); // Fetch full details if tattooId is in the URL
      initialModalOpened.current = true;
    }
  }, [tattooId, state.tattoos, state.isLoading, fetchTattooDetails]);

  // Handle click on a tattoo to open modal
  const handleTattooClick = useCallback(
    (tattoo) => {
      // Open the modal immediately
      setState((prevState) => ({
        ...prevState,
        isModalOpen: true,
        selectedTattoo: tattoo, // Optionally, you can use initial data if available
      }));
  
      // Fetch full details on click and update state when data is received
      fetchTattooDetails(tattoo.id);
      
      // Navigate to the tattoo page
      navigate(`/tattoo/${tattoo.id}`);
    },
    [navigate, fetchTattooDetails]
  );

  const handleLikeClick = async (tattooId, isLiked) => {
    // Optimistically update the UI
    setState((prevState) => ({
      ...prevState,
      tattoos: prevState.tattoos.map((tattoo) =>
        tattoo.id === tattooId
          ? { ...tattoo, isLiked: !isLiked, likes_count: isLiked ? tattoo.likes_count - 1 : tattoo.likes_count + 1 }
          : tattoo
      ),
      selectedTattoo: prevState.selectedTattoo && prevState.selectedTattoo.id === tattooId
        ? { ...prevState.selectedTattoo, isLiked: !isLiked, likes_count: isLiked ? prevState.selectedTattoo.likes_count - 1 : prevState.selectedTattoo.likes_count + 1 }
        : prevState.selectedTattoo,
    }));
  
    // Make the API call to like/unlike
    const endpoint = isLiked ? `/tattoo_designs/${tattooId}/unlike` : `/tattoo_designs/${tattooId}/like`;
    try {
      const response = await fetch(`https://koi-2028.onrender.com${endpoint}`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ user_id: user?.id }),
      });
  
      if (!response.ok) {
        throw new Error('Error updating like status');
      }
  
      // Refetch the tattoo state to ensure the UI matches the server state
      fetchTattooState(tattooId);
  
    } catch (error) {
      console.error('Error updating like status:', error);
      // Optionally, revert the state back in case of an error
      setState((prevState) => ({
        ...prevState,
        tattoos: prevState.tattoos.map((tattoo) =>
          tattoo.id === tattooId
            ? { ...tattoo, isLiked: isLiked, likes_count: isLiked ? tattoo.likes_count + 1 : tattoo.likes_count - 1 }
            : tattoo
        ),
        selectedTattoo: prevState.selectedTattoo && prevState.selectedTattoo.id === tattooId
          ? { ...prevState.selectedTattoo, isLiked: isLiked, likes_count: isLiked ? prevState.selectedTattoo.likes_count + 1 : prevState.selectedTattoo.likes_count - 1 }
          : prevState.selectedTattoo,
      }));
    }
  };
  
  // Function to refetch and update the tattoo state
  const fetchTattooState = async (tattooId) => {
    try {
      const queryString = new URLSearchParams({
        user_id: user?.id,
        ids: tattooId,  // Add 'ids' parameter to match the server-side expectation
      }).toString();
  
      const response = await fetch(`https://koi-2028.onrender.com/tattoo_designs/state?${queryString}`, {
        method: 'GET',
        // No Content-Type header needed for GET
      });
  
      if (response.ok) {
        const data = await response.json();
        setState((prevState) => ({
          ...prevState,
          tattoos: prevState.tattoos.map((tattoo) =>
            tattoo.id === tattooId
              ? { ...tattoo, isLiked: data.is_liked, likes_count: data.likes_count }
              : tattoo
          ),
          selectedTattoo: prevState.selectedTattoo && prevState.selectedTattoo.id === tattooId
            ? { ...prevState.selectedTattoo, isLiked: data.is_liked, likes_count: data.likes_count }
            : prevState.selectedTattoo,
        }));
      } else {
        console.error('Failed to fetch tattoo state:', response.statusText);
      }
    } catch (error) {
      console.error('Error fetching tattoo state:', error);
    }
  };
  
  const handleFavoriteClick = async (tattooId, isFavorited) => {
    // Optimistically update the UI
    setState((prevState) => ({
      ...prevState,
      tattoos: prevState.tattoos.map((tattoo) =>
        tattoo.id === tattooId
          ? { ...tattoo, isFavorited: !isFavorited, favorites_count: isFavorited ? tattoo.favorites_count - 1 : tattoo.favorites_count + 1 }
          : tattoo
      ),
      selectedTattoo: prevState.selectedTattoo && prevState.selectedTattoo.id === tattooId
        ? { ...prevState.selectedTattoo, isFavorited: !isFavorited, favorites_count: isFavorited ? prevState.selectedTattoo.favorites_count - 1 : prevState.selectedTattoo.favorites_count + 1 }
        : prevState.selectedTattoo,
    }));
  
    // Make the API call to favorite/unfavorite
    const endpoint = isFavorited ? `/tattoo_designs/${tattooId}/unfavorite` : `/tattoo_designs/${tattooId}/favorite`;
    try {
      const response = await fetch(`https://koi-2028.onrender.com${endpoint}`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ user_id: user?.id }),
      });
  
      if (!response.ok) {
        throw new Error('Error updating favorite status');
      }
  
      // Refetch the tattoo state to ensure the UI matches the server state
      // fetchTattooState(tattooId);
  
    } catch (error) {
      console.error('Error updating favorite status:', error);
      // Optionally, revert the state back in case of an error
      setState((prevState) => ({
        ...prevState,
        tattoos: prevState.tattoos.map((tattoo) =>
          tattoo.id === tattooId
            ? { ...tattoo, isFavorited: isFavorited, favorites_count: isFavorited ? tattoo.favorites_count + 1 : tattoo.favorites_count - 1 }
            : tattoo
        ),
        selectedTattoo: prevState.selectedTattoo && prevState.selectedTattoo.id === tattooId
          ? { ...prevState.selectedTattoo, isFavorited: isFavorited, favorites_count: isFavorited ? prevState.selectedTattoo.favorites_count + 1 : prevState.selectedTattoo.favorites_count - 1 }
          : prevState.selectedTattoo,
      }));
    }
  };


  const handleCloseModal = useCallback(() => {
  if (state.selectedTattoo) {
    // Avoid re-fetching or resetting the state when the modal closes
    // Ensure the current `selectedTattoo` state remains intact
    // console.log("Modal closing. Retaining updated state for selectedTattoo:", state.selectedTattoo);

    // Close the modal without resetting or re-fetching the state
    setState((prevState) => ({
      ...prevState,
      isModalOpen: false,
    }));
  }
  
  // Optionally navigate away, but avoid re-triggering fetch logic
  navigate('/');
}, [navigate, state.selectedTattoo]);


  // Intersection observer callback to track visibility
  const handleIntersection = useCallback(
    debounce((entries) => {
      const updatedVisibleIds = entries.reduce((acc, entry) => {
        const tattooId = entry.target.getAttribute('data-tattoo-id');
        if (tattooId) {
          acc[tattooId] = entry.isIntersecting;
        }
        return acc;
      }, {});

      const visibleTattooIds = Object.keys(updatedVisibleIds).filter((id) => updatedVisibleIds[id]);

      const resetSkeletons = {};
      const loadingStates = {};

      setState((prevState) => {
        state.tattoos.forEach((tattoo) => {
          const tattooIdStr = tattoo.id.toString();
          resetSkeletons[tattoo.id] = !visibleTattooIds.includes(tattooIdStr) && !prevState.resetSkeletons[tattoo.id];
          loadingStates[tattoo.id] = !visibleTattooIds.includes(tattooIdStr);
        });

        return {
          ...prevState,
          visibleTattoos: visibleTattooIds,
          resetSkeletons,
          loadingStates,
        };
      });
    },)
  );

  // Setup the IntersectionObserver
  useEffect(() => {
    observer.current = new IntersectionObserver(handleIntersection, { threshold: 0.01, rootMargin: '500px 0px 500px 0px' });
    Object.values(tattooRefs.current).forEach((element) => {
      observer.current.observe(element);
    });

    return () => {
      observer.current.disconnect();
      handleIntersection.cancel();
    };
  }, [state.tattoos, handleIntersection]);

  

  // Throttled scroll handler for infinite scroll
  // const throttledScrollHandler = useCallback(
  //   throttle(() => {
  //     const { scrollHeight } = document.documentElement;
  //     const { scrollTop, clientHeight } = document.documentElement;

  //     if (scrollTop + clientHeight >= scrollHeight - 100 && !state.isFetching) {
  //       setState((prevState) => ({ ...prevState, scrollLock: true }));
  //       fetchTattooPreviews(20, true);
  //     }
  //   }, 100),
  //   [fetchTattooPreviews, state.isFetching]
  // );

  // useEffect(() => {
  //   window.addEventListener('scroll', throttledScrollHandler);
  //   return () => {
  //     window.removeEventListener('scroll', throttledScrollHandler);
  //     throttledScrollHandler.cancel();
  //   };
  // }, [throttledScrollHandler]);

  const handleModalStateChange = ({ isLiked, isFavorited, likeCount, favoriteCount }) => {
  console.log("handleModalStateChange called with:", {
    isLiked,
    isFavorited,
    likeCount,
    favoriteCount,
  });

  setState((prevState) => {
    // Update the selectedTattoo
    const updatedSelectedTattoo = {
      ...prevState.selectedTattoo,
      isLiked: isLiked !== undefined ? isLiked : prevState.selectedTattoo.isLiked,
      isFavorited: isFavorited !== undefined ? isFavorited : prevState.selectedTattoo.isFavorited,
      likes_count: likeCount !== undefined ? likeCount : prevState.selectedTattoo.likes_count,
      favorites_count: favoriteCount !== undefined ? favoriteCount : prevState.selectedTattoo.favorites_count,
    };

    // Update the tattoos array with the updated tattoo
    const updatedTattoos = prevState.tattoos.map((tattoo) =>
      tattoo.id === prevState.selectedTattoo.id
        ? { ...tattoo, ...updatedSelectedTattoo } // Update the correct tattoo in the array
        : tattoo
    );

    

    return {
      ...prevState,
      selectedTattoo: updatedSelectedTattoo,
      tattoos: updatedTattoos, // Save the updated tattoos array
    };
  });
};

  const loadingMessage = state.isLoading && state.tattoos.length === 0 && (
    <div className="flex justify-center items-center h-screen">
      <p className="text-white text-xl">Loading designs...</p>
    </div>
  );
  

  return (
    <div className="flex">
      <div className="content-container mt-16 pt-2 sm:pt-0 sm:mt-0 pl-2 sm:pl-0 bg-gray-800 min-h-screen">
        {loadingMessage || (
          <>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-2 sm:gap-4 p-2 sm:p-4 md:pr-20 min-h-screen">
              {state.tattoos.map((tattoo) => (
                <div
                  key={tattoo.id}
                  data-tattoo-id={tattoo.id}
                  ref={(el) => (tattooRefs.current[tattoo.id] = el)}
                  className="p-2 sm:p-0"
                >
                  <Suspense fallback={<div className="h-48 w-48 bg-gray-300 animate-pulse" />}>
                    <DisplayTattoo
                      id={tattoo.id}
                      preview_image_url={tattoo.preview_image_url}
                      // Conditionally pass updated values if this is the selected tattoo
                      likes_count={
                        tattoo.id === state.selectedTattoo?.id
                          ? state.selectedTattoo.likes_count
                          : tattoo.likes_count
                      }
                      favorites_count={
                        tattoo.id === state.selectedTattoo?.id
                          ? state.selectedTattoo.favorites_count
                          : tattoo.favorites_count
                      }
                      isLiked={
                        tattoo.id === state.selectedTattoo?.id
                          ? state.selectedTattoo.isLiked
                          : tattoo.isLiked
                      }
                      isFavorited={
                        tattoo.id === state.selectedTattoo?.id
                          ? state.selectedTattoo.isFavorited
                          : tattoo.isFavorited
                      }
                      onClick={() => handleTattooClick(tattoo)}
                      onLikeClick={() => handleLikeClick(tattoo.id, tattoo.isLiked)}
                      onFavoriteClick={() => handleFavoriteClick(tattoo.id, tattoo.isFavorited)}
                      isVisible={state.visibleTattoos.includes(tattoo.id.toString())}
                      resetToSkeleton={state.resetSkeletons[tattoo.id]}
                      loading={state.loadingStates[tattoo.id]}
                      
                    />
                  </Suspense>
                </div>
              ))}
            </div>
            {state.isFetching && <div className="text-white text-center py-4 bottom-0 left-0 right-0">Loading more designs...</div>}
          </>
        )}
      </div>
      {state.isModalOpen && state.selectedTattoo && (
        <ModelPreviewModal
          isOpen={state.isModalOpen}
          onClose={handleCloseModal}
          modelUrl={state.selectedTattoo.obj_file}
          images={state.selectedTattoo.images}
          positions={state.selectedTattoo.positions}
          rotations={state.selectedTattoo.rotations}
          scales={state.selectedTattoo.scales}
          tattoo={state.selectedTattoo}
          onStateChange={handleModalStateChange}
        />
      )}
    </div>
  );
}

export default HomePage;