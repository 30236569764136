import React from 'react';
import { useNavigate } from 'react-router-dom';

const SuccessPage = () => {
    const navigate = useNavigate();

    const handleExploreClick = () => {
        navigate('/create'); // Update with the route for premium features
    };

    return (
        <div className="flex flex-col items-center justify-center h-screen bg-gray-800 text-center">
            <h1 className="text-4xl font-bold text-rose-600 mb-4">
                🎉 Congratulations! 🎉
            </h1>
            <p className="text-lg text-white mb-6">
                You’ve unlocked premium access! 🥳 Enjoy all the amazing features we have to offer.
            </p>
            <button
                onClick={handleExploreClick}
                className="bg-rose-600 text-white py-2 px-4 rounded shadow hover:bg-rose-700 focus:outline-none"
            >
                🚀 Explore Premium Features
            </button>
        </div>
    );
};

export default SuccessPage;