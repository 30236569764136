import React, { useState, useContext } from 'react';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import { useNavigate, useLocation } from 'react-router-dom';
import { AuthContext } from './AuthContext';

function Login({ onClose }) {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [forgotPasswordEmail, setForgotPasswordEmail] = useState('');
    const [showForgotPassword, setShowForgotPassword] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();

    const { login, googleLogin, setUser } = useContext(AuthContext);

    const previousPage = location.state?.from || '/';

    // Handle email/password login
    const handleLogin = async (e) => {
        e.preventDefault();
        if (!email || !password) {
            setMessage('Email and password are required.');
            return;
        }
        setLoading(true);
        try {
            const response = await fetch('https://koi-2028.onrender.com/login', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email, password }),
            });
    
            const data = await response.json();
    
            if (response.ok) {
                setMessage('Login successful!');
                setUser(data.user); // Update user context
                localStorage.setItem('user', JSON.stringify(data.user));
                // console.log('User logged in:', data.user);
                setTimeout(() => {
                    onClose(); // Close the modal on success
                    navigate(previousPage);
                }, 500);
            } else {
                setMessage(data.error || 'Invalid email or password.');
            }
        } catch (error) {
            console.error('Login error:', error);
            setMessage('An error occurred. Please try again.');
        } finally {
            setLoading(false);
        }
    };
    // Handle Google login success
    const handleGoogleLoginSuccess = async (response) => {
        setLoading(true);
        setMessage('');
        try {
            const result = await fetch('https://koi-2028.onrender.com/login', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ google_token: response.credential }),
            });

            if (!result.ok) {
                throw new Error('Google login failed.');
            }

            const data = await result.json();
            if (data.user) {
                setMessage('Login successful with Google!');
                googleLogin(data.user); // Store user in AuthContext
            } else {
                setMessage(data.error || 'Google login failed.');
            }
        } catch (error) {
            console.error('Google login error:', error);
            setMessage('Google login failed. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    // Handle Forgot Password
    const handleForgotPassword = async () => {
        setLoading(true);
        setMessage('');
        try {
            const response = await fetch('https://koi-2028.onrender.com/auth/forgot-password', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email: forgotPasswordEmail }),
            });

            const data = await response.json();
            if (response.ok) {
                setMessage(data.message || 'Password reset email sent.');
            } else {
                setMessage(data.error || 'Failed to send reset email.');
            }
        } catch (error) {
            console.error('Forgot Password error:', error);
            setMessage('An error occurred. Please try again.');
        } finally {
            setLoading(false);
            setShowForgotPassword(false);
        }
    };

    return (
        <GoogleOAuthProvider clientId="513619425094-tuoekhjks9mdb6cjoifrdc7m39g96vsa.apps.googleusercontent.com">
            <div className="flex items-center justify-center">
                <div className="w-full max-w-md p-8 space-y-4 bg-white rounded-lg shadow-lg relative">
                    {/* Loading Overlay */}
                    {loading && (
                        <div className="absolute inset-0 bg-gray-100 bg-opacity-75 flex items-center justify-center">
                            <div className="loader">Loading...</div> {/* Replace with a spinner */}
                        </div>
                    )}
                    <h2 className="text-2xl font-bold text-center text-gray-700">Login</h2>
                    <form onSubmit={handleLogin} className="space-y-6">
                        <div>
                            <label className="block text-sm font-medium text-gray-700">Email</label>
                            <input
                                type="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                                className="w-full px-3 py-2 mt-1 text-gray-900 bg-gray-200 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500"
                            />
                        </div>
                        <div>
                            <label className="block text-sm font-medium text-gray-700">Password</label>
                            <input
                                type="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                required
                                className="w-full px-3 py-2 mt-1 text-gray-900 bg-gray-200 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500"
                            />
                        </div>
                        <div className="flex items-center justify-between">
                            <button
                                type="submit"
                                className="w-full px-4 py-2 font-medium text-white bg-rose-500 rounded-lg hover:bg-rose-600 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                disabled={loading}
                            >
                                Login
                            </button>
                        </div>
                        <p
                            onClick={() => setShowForgotPassword(true)}
                            className="text-sm text-center text-red-500 cursor-pointer"
                        >
                            Forgot Password?
                        </p>
                    </form>
                    <div className="flex items-center justify-center mt-4 space-x-2">
                        <span className="text-sm font-medium text-gray-500">Or</span>
                    </div>
                    <div className="flex items-center justify-center">
                        <GoogleLogin
                            onSuccess={handleGoogleLoginSuccess}
                            onError={() => setMessage('Google login failed. Please try again.')}
                            text="signin_with"
                            className="w-full"
                        />
                    </div>
                    {message && <p className="mt-4 text-center text-red-500">{message}</p>}
                </div>

                {/* Forgot Password Modal */}
                {showForgotPassword && (
                    <div className="absolute inset-0 bg-gray-900 bg-opacity-75 flex items-center justify-center">
                        <div className="bg-white p-6 rounded-lg space-y-4 shadow-lg">
                            <h3 className="text-lg font-bold text-gray-700">Forgot Password</h3>
                            <input
                                type="email"
                                value={forgotPasswordEmail}
                                onChange={(e) => setForgotPasswordEmail(e.target.value)}
                                placeholder="Enter your email"
                                className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500"
                            />
                            <button
                                onClick={handleForgotPassword}
                                className="w-full px-4 py-2 text-white bg-rose-500 rounded-lg hover:bg-rose-600"
                                disabled={loading}
                            >
                                Send Reset Email
                            </button>
                            <button
                                onClick={() => setShowForgotPassword(false)}
                                className="w-full px-4 py-2 mt-2 text-gray-700 bg-gray-200 rounded-lg hover:bg-gray-300"
                            >
                                Cancel
                            </button>
                        </div>
                    </div>
                )}
            </div>
        </GoogleOAuthProvider>
    );
}

export default Login;