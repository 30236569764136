import React, { useState, useContext, useEffect } from 'react';
import { AuthContext } from './AuthContext';
import Login from './Login';

function Settings() {
    const { user, updateUserProfile, logout } = useContext(AuthContext);
    const [email, setEmail] = useState(user?.email || '');
    const [username, setUsername] = useState(user?.username || '');
    const [loading, setLoading] = useState(true);
    const [subscriptionDetails, setSubscriptionDetails] = useState(null);
    const [error, setError] = useState(null);

    const [openSection, setOpenSection] = useState(null); // Tracks open dropdown section
    const [showAccountOptions, setShowAccountOptions] = useState(false); // Hides account options
    const [showSubscriptionOptions, setShowSubscriptionOptions] = useState(false); // Hides subscription options
    const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);

    useEffect(() => {
        fetchSubscriptionDetails();
    }, [user]);

    const fetchSubscriptionDetails = async () => {
        try {
            const response = await fetch('https://koi-2028.onrender.com/get-subscription-details', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ user_id: user.id }),
            });

            const data = await response.json();
            if (response.ok) {
                setSubscriptionDetails(data);
            } else {
                setError(data.error || 'Unable to fetch subscription details');
            }
        } catch (err) {
            setError('An error occurred while fetching subscription details.');
        } finally {
            setLoading(false);
        }
    };

    const isPaused = subscriptionDetails?.pause_collection !== null;

    const toggleSection = (section) => {
        setOpenSection(openSection === section ? null : section);
        setShowAccountOptions(false);
        setShowSubscriptionOptions(false);
    };

    const handlePauseSubscription = async () => {
        try {
            const response = await fetch('https://koi-2028.onrender.com/pause-subscription', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ user_id: user.id }),
            });
    
            if (response.ok) {
                alert('Subscription pause initiated!');
                await fetchSubscriptionDetails(); // Refetch subscription details
            } else {
                const error = await response.json();
                alert(`Error: ${error.message}`);
            }
        } catch (err) {
            alert('An unexpected error occurred.');
        }
    };
    
    const handleCancelSubscription = async () => {
        try {
            const response = await fetch('https://koi-2028.onrender.com/cancel-subscription', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ user_id: user.id }),
            });
    
            if (response.ok) {
                const result = await response.json();
                alert('Subscription successfully canceled!');
                await fetchSubscriptionDetails(); // Refetch subscription details
            } else {
                const error = await response.json();
                alert(`Error: ${error.message || 'Failed to cancel subscription'}`);
            }
        } catch (err) {
            console.error('Cancellation error:', err);
            alert('An unexpected error occurred while canceling your subscription.');
        }
    };

    const handleDeleteAccount = async () => {
        try {
            const response = await fetch(
                `https://koi-2028.onrender.com/users/${user.id}/delete-profile`,
                {
                    method: "DELETE",
                    headers: {
                        "Content-Type": "application/json",
                    },
                }
            );

            if (response.ok) {
                alert("Your account has been deleted successfully.");
                setShowDeleteConfirm(false); // Close the modal
                logout(); // Log the user out after deletion
            } else {
                const data = await response.json();
                alert(`Error: ${data.error || "Failed to delete account"}`);
            }
        } catch (error) {
            alert("An unexpected error occurred. Please try again.");
        }
    };

    const handleSaveChanges = () => {
        const updatedUser = { ...user, email, username };
        updateUserProfile(updatedUser);
        alert('Settings updated!');
    };

    if (!user) {
        return (
            <div className="flex items-center justify-center min-h-screen bg-gray-800 text-white">
                <div className="text-center">
                    <h2 className="text-2xl font-bold mb-4">You need to log in to access settings</h2>
                    <Login />
                </div>
            </div>
        );
    }

    return (
        <div className="settings-container p-6 ml-0 sm:ml-16 bg-gray-800 text-white min-h-screen">
            <h1 className="text-3xl font-bold mb-6">Settings</h1>

            {/* Account Settings Dropdown */}
            <section>
                <div
                    className="cursor-pointer flex justify-between items-center bg-gray-700 p-4 rounded mb-2"
                    onClick={() => toggleSection('account')}
                >
                    <h2 className="text-2xl font-semibold">Account Settings</h2>
                    <span>{openSection === 'account' ? '-' : '+'}</span>
                </div>
                {openSection === 'account' && (
                    <div className="p-4 bg-gray-600 rounded mb-4">
                        <div className="mb-4">
                            <label className="block text-sm mb-2">Email</label>
                            <input
                                type="email"
                                value={user.email}
                                onChange={(e) => setEmail(e.target.value)}
                                className="w-full p-2 rounded bg-gray-700 text-white"
                            />
                        </div>
                        <div className="mb-4">
                            <label className="block text-sm mb-2">Username</label>
                            <input
                                type="text"
                                value={user.username }
                                onChange={(e) => setUsername(e.target.value)}
                                className="w-full p-2 rounded bg-gray-700 text-white"
                            />
                        </div>
                        

                        {/* More Options for Account Settings */}
                        <button
                            onClick={() => setShowAccountOptions(!showAccountOptions)}
                            className="mt-4 px-4 py-2 bg-gray-500 rounded hover:bg-gray-600"
                        >
                            More Options
                        </button>
                        {showAccountOptions && (
                            <div className="mt-4 border-t border-gray-400 pt-4">
                                <button
                                    onClick={() => setShowDeleteConfirm(true)}
                                    className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600 transition duration-200"
                                >
                                    Delete Account
                                </button>
                            </div>
                        )}
                    </div>
                )}
            </section>

            {/* Delete Account Confirmation Modal */}
            {showDeleteConfirm && (
                <div className="fixed inset-0 bg-gray-900 bg-opacity-50 flex items-center justify-center z-50">
                    <div className="bg-white rounded-lg shadow-lg p-6 w-96">
                        <h3 className="text-xl font-semibold text-center text-gray-800 mb-4">Delete Account</h3>
                        <p className="text-gray-700 mb-6">
                            Are you sure you want to delete your account? This action cannot be undone.
                        </p>
                        <div className="flex justify-center space-x-4">
                            <button
                                onClick={handleDeleteAccount}
                                className="bg-red-500 text-white px-6 py-2 rounded-full shadow-md hover:bg-red-600 transition duration-200 ease-in-out"
                            >
                                Yes, Delete
                            </button>
                            <button
                                onClick={() => setShowDeleteConfirm(false)}
                                className="bg-gray-300 text-gray-800 px-6 py-2 rounded-full shadow-md hover:bg-gray-400 transition duration-200 ease-in-out"
                            >
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
            )}

            {/* Subscription Details Dropdown */}
            <section>
                <div
                    className="cursor-pointer flex justify-between items-center bg-gray-700 p-4 rounded mb-2"
                    onClick={() => toggleSection('subscription')}
                >
                    <h2 className="text-2xl font-semibold">Subscription Details</h2>
                    <span>{openSection === 'subscription' ? '-' : '+'}</span>
                </div>
                {openSection === 'subscription' && (
                    <div className="p-4 bg-gray-600 rounded mb-4">
                        {loading ? (
                            <p>Loading subscription details...</p>
                        ) : error ? (
                            <p className="text-red-500">{error}</p>
                        ) : subscriptionDetails ? (
                            <div>
                                <p>Status: {subscriptionDetails.status}</p>
                                <p>Payment Method: {subscriptionDetails.payment_method}</p>
                                <p>
                                    Next Billing Date:{' '}
                                    {new Date(subscriptionDetails.next_billing_date * 1000).toLocaleDateString()}
                                </p>
                                <p>Plan: {subscriptionDetails.plan_name}</p>
                                <p>Amount: ${subscriptionDetails.amount}</p>

                                {/* Show Upgrade Button if Not Premium */}
                                {subscriptionDetails.plan_name === 'Free' ? (
                                    <button
                                        onClick={() => (window.location.href = '/#/subscribe')}
                                        className="mt-4 px-4 py-2 bg-rose-500 text-white rounded hover:bg-rose-600"
                                    >
                                        Upgrade to Premium
                                    </button>
                                ) : (
                                    // Visible only for Premium members
                                    <div>
                                        <button
                                            onClick={() => setShowSubscriptionOptions(!showSubscriptionOptions)}
                                            className="mt-4 px-4 py-2 bg-gray-500 rounded hover:bg-gray-600"
                                        >
                                            More Options
                                        </button>
                                        {showSubscriptionOptions && (
                                            <div className="mt-4 border-t border-gray-400 pt-4">
                                                <button
                                                    onClick={handleCancelSubscription}
                                                    className="px-4 py-2 bg-rose-500 text-white rounded hover:bg-rose-600"
                                                >
                                                    Cancel Subscription
                                                </button>
                                            </div>
                                        )}
                                    </div>
                                )}
                            </div>
                        ) : (
                            <div>
                                <p>You don't have an active subscription.</p>
                                <button
                                    onClick={() => (window.location.href = '/subscribe')}
                                    className="mt-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
                                >
                                    Upgrade to Premium
                                </button>
                            </div>
                        )}
                    </div>
                )}
            </section>

            {/* Logout Section */}
            <section>
                <div
                    className="cursor-pointer flex justify-between items-center bg-gray-700 p-4 rounded mb-2"
                    onClick={() => toggleSection('logout')}
                >
                    <h2 className="text-2xl font-semibold">Logout</h2>
                    <span>{openSection === 'logout' ? '-' : '+'}</span>
                </div>
                {openSection === 'logout' && (
                    <div className="p-4 bg-gray-600 rounded">
                        <button
                            onClick={logout}
                            className="px-4 py-2 bg-rose-500 text-white rounded hover:bg-rose-600"
                        >
                            Logout
                        </button>
                    </div>
                )}
            </section>
        </div>
    );
}

export default Settings;
